import { SET_THEME } from './actions'
import { THEMES } from './themes'

const defaultType = localStorage.getItem('theme') || THEMES.LIGHT.palette.type

const defaultTheme =
  defaultType === THEMES.LIGHT.palette.type ? THEMES.LIGHT : THEMES.DARK

const theme = (state = defaultTheme, action) => {
  switch (action.type) {
    case SET_THEME:
      const type = action.theme.palette.type
      localStorage.setItem('theme', type)
      return action.theme
    default:
      return state
  }
}

export default theme
