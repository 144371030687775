import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import ServiceWorker from 'components/serviceWorker/ServiceWorkerComponent'
import ThemeProvider from 'components/theme/ThemeProvider'
import store from 'store'
import Bootstrap from 'Bootstrap'
import 'typeface-roboto'
import './index.css'

/* istanbul ignore file */

ReactDOM.render(
  <ServiceWorker>
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <Bootstrap />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </ServiceWorker>,
  document.getElementById('root')
)
