import React from 'react'
import URLSearchParams from 'url-search-params'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import Slide from '@material-ui/core/Slide'
import { Link } from 'react-router-dom'
import Options from './Options'
import Email from './Email'
import CloseableSnackbar from './CloseableSnackbar'
import Logo from './../logo/LogoWithName'
import userApi from './../../api/user/user'

/* istanbul ignore next */
const styles = theme => ({
  article: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh'
  },
  actionSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column'
  },
  formSection: {
    flex: 1,
    overflow: 'hidden',
    minWidth: '375px',
    maxWidth: '736px',
    width: '100%'
  },
  logoMask: {
    display: 'flex',
    alignItems: 'center',
    height: '100px'
  }
})

const defaultFields = {
  email: new URLSearchParams(window.location.search).get('email') || '',
  password: ''
}

export class Login extends React.Component {
  state = {
    fields: { ...defaultFields, ...this.props.fields },
    fieldErrors: {},
    snackbarActive: false,
    snackbarAction: false,
    snackbarActionText: '',
    snackbarMessage: '',
    showEmail: false,
    validated: true
  }
  _handleFailedSendEmailVerification = e => {
    console.error('failed to send email verification', e)
    this.setState({
      fieldErrors: {},
      snackbarActive: true,
      snackbarAction:
        /* istanbul ignore next */
        () => userApi.sendEmailVerificationNoContinue(),
      snackbarActionText: 'Resend email',
      snackbarMessage: 'Please verify your email to continue'
    })
  }
  _handleFailedSignUp = ({ e, fieldErrors }) => {
    let errors = { ...fieldErrors }
    if (e.code === 'auth/invalid-email') {
      errors.email = 'Invalid email'
    }
    if (e.code === 'auth/email-already-in-use') {
      errors.email = 'Email is already in use'
    }
    if (e.code === 'auth/weak-password') {
      errors.password = 'Password is too weak'
    }
    if (!errors.email && !errors.password) {
      errors.email = e.message
    }
    this.setState({ fieldErrors: errors })
  }
  onSignUp = ({ e, fields, fieldErrors }) => {
    e.preventDefault()
    return userApi
      .createUserWithEmailAndPassword(fields.email, fields.password)
      .then(
        user => {
          return userApi.sendEmailVerification().then(
            () => {
              this.setState({
                fieldErrors: {},
                snackbarActive: true,
                snackbarMessage: 'Please verify your email to continue'
              })
            },
            /* istanbul ignore next */
            e => this._handleFailedSendEmailVerification(e)
          )
        },
        /* istanbul ignore next */
        e => this._handleFailedSignUp({ e, fieldErrors })
      )
  }
  render() {
    const { classes } = this.props
    const {
      fields,
      fieldErrors,
      snackbarActive,
      snackbarAction,
      snackbarActionText,
      snackbarMessage,
      showEmail,
      validated
    } = this.state
    return (
      <article className={classes.article}>
        <section className={classes.actionSection}>
          <header className={classes.logoMask}>
            <Logo />
          </header>
          <div className={classes.actionButtons}>
            <Options
              buttonText="Sign up with"
              onEmailClick={
                /* istanbul ignore next */
                () => this.setState({ showEmail: !showEmail })
              }
              onError={
                /* istanbul ignore next */
                ({ message, snackbarAction, snackbarActionText }) =>
                  this.setState({
                    snackbarActive: true,
                    snackbarMessage: message,
                    snackbarAction,
                    snackbarActionText
                  })
              }
            />
            <Button
              component={Link}
              to="/"
              color="primary"
              data-cy="already-have-account"
            >
              Already have an account?
            </Button>
          </div>
        </section>
        <section className={classes.formSection}>
          <Slide direction="up" in={showEmail}>
            <Email
              fields={fields}
              fieldErrors={fieldErrors}
              onInputChange={
                /* istanbul ignore next */
                ({ fields, fieldErrors }) =>
                  this.setState({ fields, fieldErrors })
              }
              onValidate={
                /* istanbul ignore next */
                valid => {
                  if (valid === validated) return this
                  this.setState({ validated: valid })
                }
              }
              actions={[
                <Button
                  key={0}
                  fullWidth={true}
                  type="submit"
                  color="primary"
                  disabled={validated}
                  onClick={
                    /* istanbul ignore next */
                    e => this.onSignUp({ e, fields, fieldErrors })
                  }
                >
                  Sign Up
                </Button>
              ]}
            />
          </Slide>
        </section>
        <CloseableSnackbar
          open={snackbarActive}
          action={snackbarAction}
          actionText={snackbarActionText}
          message={snackbarMessage}
          handleClose={
            /* istanbul ignore next */
            () => {
              this.setState({
                snackbarActive: false,
                snackbarAction: false,
                snackbarMessage: ''
              })
            }
          }
        />
      </article>
    )
  }
}

export default withStyles(styles)(Login)
