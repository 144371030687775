import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withStyles } from '@material-ui/styles'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'
import InputField from 'components/form/InputField'
import { isEmail } from './../../util'

/* istanbul ignore next */
const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing()
  }
})

export class Email extends React.Component {
  static defaultProps = {
    fields: {},
    fieldErrors: {}
  }
  onInputChange = ({ name, value, error }) => {
    const { onInputChange, onValidate } = this.props
    const { fields, fieldErrors } = this.props

    fields[name] = value
    fieldErrors[name] = error

    onInputChange({ fields, fieldErrors })
    onValidate(this.validate({ fields, fieldErrors }))
  }
  validate = ({ fields, fieldErrors }) => {
    const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k])

    if (!fields.email) return true
    if (!isEmail(fields.email)) return true
    if (!fields.password) return true
    if (errMessages.length) return true

    return false
  }
  render() {
    const { classes, actions, fields, fieldErrors } = this.props
    return (
      <form className={classes.form}>
        <InputField
          required
          fullWidth={true}
          autoFocus={true}
          type="email"
          label="Email"
          name="email"
          error={fieldErrors.email}
          value={fields.email}
          onChange={this.onInputChange}
          validate={
            /* istanbul ignore next */ val => (val ? false : 'Email Required')
          }
          startAdornment={
            <InputAdornment position="start">
              <EmailIcon color="action" />
            </InputAdornment>
          }
        />
        <InputField
          required
          type="password"
          label="Password"
          name="password"
          error={fieldErrors.password}
          value={fields.password}
          onChange={this.onInputChange}
          validate={
            /* istanbul ignore next */
            val => (val ? false : 'Password Required')
          }
          startAdornment={
            <InputAdornment position="start">
              <LockIcon color="action" />
            </InputAdornment>
          }
        />
        <div style={{ display: 'flex' }}>{actions}</div>
      </form>
    )
  }
}

export default withStyles(styles)(Email)
