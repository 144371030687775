import { FIREBASE_CONFIG } from './environments'

export const AMOUNT_FORMAT = '0.00'
export const APP_NAME = 'Keepify'
export const APP_URL = `https://${FIREBASE_CONFIG.authDomain}`
export const CSV_DAY_DATE_FORMAT = 'MM/DD/YYYY'
export const DAY_DATE_FORMAT = 'MM/DD/YY'
export const DRAWER_WIDTH = 240
export const FAQ_LINK = 'https://keepify.com/help/'
export const HELP_EMAIL = 'help@keepify.com'
export const LOCAL_STORE_KEY = 'keepify'
export const IMPORTED_CATEGORY_ID = '00000000000000000000'
export const MONTH_FORMAT = 'YYYY-MM'
export const MONTH_YEAR_FORMAT = 'MMM YYYY'
export const NUMBER_FORMAT = '$0,0.00'
// INFO: we don't need to be directly aligned with stripe there are their possible values:
// https://stripe.com/docs/api/subscriptions/object
// incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid
export const SUBSCRIPTION_STATUS = Object.freeze({
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  PAST_DUE: 'pastDue',
  TRIALING: 'trialing'
})
export const SURPLUS_NUMBER_FORMAT = '+$0,0.00'
export const SUPPORT_EMAIL = 'support@keepify.com'
export const TRANSACTION_DATE_FORMAT = 'YYYY-MM-DD'
export const TRIAL_PERIOD_DAYS = 34
