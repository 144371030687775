import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'
import UserMenu from 'components/userMenu/UserMenu'
import UsersList from 'components/users/Container'
import NotificationsIcon from 'components/notifications/NotificationsIcon'
import ThemeSwitch from 'components/theme/ThemeSwitch'
import NavIcon from './NavIcon'
import { APP_NAME, DRAWER_WIDTH } from './../../constants'

/* istanbul ignore next */
const styles = theme => ({
  appBar: {
    position: 'absolute',
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  title: {
    flex: 1,
    marginLeft: theme.spacing(1.5)
  },
  titleLink: {
    textDecoration: 'none',
    color: 'inherit'
  }
})

export const Header = ({ classes, budget, onMenuClick }) => {
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open navigation"
          onClick={onMenuClick}
          className={`nav--menu ${classes.navIconHide}`}
        >
          <MenuIcon />
        </IconButton>
        <NavIcon />
        <Typography
          className={`${classes.title} header--title`}
          variant="h6"
          color="inherit"
          noWrap
        >
          <Link to="/" className={classes.titleLink}>
            {budget ? budget.name : APP_NAME}
          </Link>
        </Typography>
        <ThemeSwitch />
        <NotificationsIcon color="inherit" />
        <UsersList />
        <UserMenu />
      </Toolbar>
    </AppBar>
  )
}
const HeaderWithStyles = withStyles(styles)(Header)

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => ({
  budget: state.budgets.activeBudget
})

// With router is not used in this component but the child component
// `NavIcon` needs it to recieve router updates
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(connect(mapStateToProps, null)(HeaderWithStyles))
