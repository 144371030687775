import React, { useState } from 'react'
import { withStyles } from '@material-ui/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import Badge from '@material-ui/core/Badge'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import AddIcon from '@material-ui/icons/Add'
import Avatar from './Avatar'
import withRoles from './../withRoles/withRoles'
import roles from './../users/roles'

/* istanbul ignore next */
const styles = theme => ({
  container: {
    position: 'relative',
    height: 40,
    width: 40,
    marginRight: theme.spacing()
  },
  speedDial: {
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    }
  },
  speedDialButton: {
    '&:disabled': {
      backgroundColor: 'unset'
    }
  }
})

export const UserListSpeedDial = ({
  classes,
  disabled,
  onAddUser,
  onEditUser,
  users = [],
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={classes.container}>
      <SpeedDial
        data-cy="add-shared-user"
        ariaLabel="Add/Edit User"
        FabProps={{
          classes: { root: classes.speedDialButton },
          color: open ? 'secondary' : 'primary',
          disabled,
          size: 'small'
        }}
        className="user-list--button__add"
        classes={{ fab: classes.speedDial }}
        direction="down"
        icon={
          <SpeedDialIcon
            icon={
              <Badge badgeContent={users.length}>
                <PersonAddIcon />
              </Badge>
            }
            openIcon={<AddIcon />}
          />
        }
        // onBlur={/* istanbul ignore next */ () => setOpen(false)}
        onClick={
          /* istanbul ignore next */ evt => {
            setOpen(!open)
            if (open) {
              onAddUser(evt)
            }
          }
        }
        onClose={/* istanbul ignore next */ () => setOpen(false)}
        // onFocus={/* istanbul ignore next */ () => setOpen(true)}
        onMouseEnter={/* istanbul ignore next */ () => setOpen(true)}
        onMouseLeave={/* istanbul ignore next */ () => setOpen(false)}
        open={open}
        {...rest}
      >
        {users.map((user, i) => (
          <SpeedDialAction
            key={user.id}
            icon={<Avatar user={user} />}
            tooltipTitle={user.email || ''}
            onClick={
              /* istanbul ignore next */ evt => {
                evt.stopPropagation()
                setOpen(false)
                onEditUser(evt, user)
              }
            }
          />
        ))}
      </SpeedDial>
    </div>
  )
}
const WithRolesSpeedDial = withRoles(UserListSpeedDial, [
  roles.OWNER,
  roles.EDITOR
])

export default withStyles(styles)(WithRolesSpeedDial)
