/* istanbul ignore file */
import budgetsApi from './../budgets/budgets'
import store from './../../store'
import { setBudgets } from './../budgets/actions'

// The intend of this module is to hydrate the budgets redux store
// utilizing a firebase real-time listener

let unsubscribe = () => {}
export const budgetListener = {
  listen: firebaseUser => {
    unsubscribe()
    unsubscribe = budgetsApi.listen(
      firebaseUser,
      budgets => {
        let items = []
        budgets.forEach(data => {
          items.push({ ...data })
        })
        store.dispatch(setBudgets(items))
      },
      err => {
        // This may occur if there are no available resources yet
        console.info('budget listener:\n', err)
      }
    )
  },
  unsubscribe: () => unsubscribe()
}
