import React, { Component } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { withSubscription } from 'components/withSubscription/withSubscription'
import userApi from 'api/user/user'
import { analytics as firebaseAnalytics } from 'api/firebase/firebase'
import analytics from '../../analytics'
import { STRIPE_CHECKOUT } from './../../environments'
import { SUBSCRIPTION_STATUS } from './../../constants'

const WithSubscriptionButton = withSubscription(Button, [
  SUBSCRIPTION_STATUS.CANCELLED
])

/* istanbul ignore next */
const styles = theme => ({
  button: {
    marginTop: theme.spacing(2)
  },
  stripeElement: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing()}px ${theme.spacing(2)}px 0`
  }
})

export const CheckoutCard = ({ classes, onSubmit, stripe }) => (
  <React.Fragment>
    <div className={classes.stripeElement} data-cy="stripe-card">
      <CardElement />
    </div>
    <WithSubscriptionButton
      className={classes.button}
      data-cy="subscribe"
      fullWidth={true}
      onClick={/* istanbul ignore next */ () => onSubmit(stripe)}
      color="primary"
    >
      Subscribe
    </WithSubscriptionButton>
  </React.Fragment>
)
const StyledCheckoutCard = withStyles(styles)(CheckoutCard)
const StripeCheckoutCard = injectStripe(StyledCheckoutCard)

const logAnalytics = label => {
  analytics.event({
    category: 'subscribe',
    action: 'subscribe',
    label
  })
  firebaseAnalytics.logEvent('subscribe', { action: label })
}

class CheckoutForm extends Component {
  state = { stripe: null }
  componentDidMount() {
    /* istanbul ignore else */
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(STRIPE_CHECKOUT.key) })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(STRIPE_CHECKOUT.key) })
      })
    }
  }
  handleSubmit = stripe => {
    const { onPaid } = this.props
    logAnalytics('start')

    return this.submit(stripe).then(
      user => {
        logAnalytics('success')
        onPaid()
        return user
      },
      err => {
        logAnalytics('fail')
        console.error(err)
      }
    )
  }
  submit = stripe => {
    return stripe
      .createSource({ type: 'card', usage: 'reusable' })
      .then(({ source, error }) => {
        if (error) {
          return Promise.reject(error)
        }
        return this.processPayment(source)
      })
  }
  processPayment = source => {
    const { user } = this.props
    const subscription = { ...user.subscription, source: source.id }
    logAnalytics('update user')
    return userApi.update({ id: user.id, subscription })
  }
  render() {
    const { user } = this.props
    const { stripe } = this.state
    return (
      <section>
        <StripeProvider stripe={stripe}>
          <Elements>
            <StripeCheckoutCard user={user} onSubmit={this.handleSubmit} />
          </Elements>
        </StripeProvider>
      </section>
    )
  }
}

export default CheckoutForm
