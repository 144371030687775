/* istanbul ignore file */
import {
  SET_TRANSACTIONS,
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION
} from './actionTypes'

export const setTransactions = items => {
  return {
    type: SET_TRANSACTIONS,
    items
  }
}
export const addTransaction = item => {
  return {
    type: ADD_TRANSACTION,
    item
  }
}
export const updateTransaction = item => {
  return {
    type: UPDATE_TRANSACTION,
    item
  }
}
export const deleteTransaction = item => {
  return {
    type: DELETE_TRANSACTION,
    item
  }
}
