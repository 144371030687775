import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'

const dataUrl = 'https://calendly.com/robert-keepify/30min'
const href = 'https://assets.calendly.com/assets/external/widget.css'
const srcAttribute = 'https://assets.calendly.com/assets/external/widget.js'
const body = document.querySelector('body')
const link = document.createElement('link')
const script = document.createElement('script')
link.setAttribute('rel', 'stylesheet')
link.setAttribute('href', href)
body.appendChild(link)
script.setAttribute('src', srcAttribute)
body.appendChild(script)

/* istanbul ignore next */
const styles = theme => ({
  '@global': {
    '.calendly-overlay.calendly-overlay': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)'
    }
  }
})

export const Scheduler = ({
  children,
  classes,
  component: Component,
  onClick,
  ...rest
}) => {
  const [Calendly, setCalendly] = useState(window.Calendly)
  useEffect(() => {
    if (!Calendly) {
      script.addEventListener(
        'load',
        /* istanbul ignore next */ e => setCalendly(window.Calendly)
      )
      script.addEventListener(
        'error',
        /* istanbul ignore next */ e =>
          console.error('Error loading calendly', e)
      )
      body.appendChild(script)
    }
  }, [Calendly])
  return (
    !!Calendly && (
      <Component
        onClick={evt => {
          onClick(evt)
          evt.preventDefault()
          Calendly.showPopupWidget(dataUrl)
          return false
        }}
        {...rest}
      >
        {children}
      </Component>
    )
  )
}

Scheduler.defaultProps = {
  children: 'Free 1-on-1 budget consulting',
  component: Button,
  onClick() {}
}
export default withStyles(styles)(Scheduler)
