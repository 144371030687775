import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { APP_NAME } from './../../constants'

export default ({ handleClose, open }) => (
  <Snackbar autoHideDuration={6000} onClose={handleClose} open={open}>
    <SnackbarContent
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
      aria-describedby="message-id"
      message={
        <Typography color="inherit" id="message-id">
          {`${APP_NAME} is ready for offline use`}
        </Typography>
      }
      style={{ flexWrap: 'nowrap' }}
    />
  </Snackbar>
)
