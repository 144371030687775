import React from 'react'
import moment from 'moment'
import pathToRegExp from 'path-to-regexp'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import BudgetIcon from '@material-ui/icons/AttachMoney'
import HomeIcon from '@material-ui/icons/Home'
import AccountIcon from '@material-ui/icons/AccountBalance'
import EnvelopeIcon from '@material-ui/icons/Mail'
import TransactionsIcon from '@material-ui/icons/SwapHoriz'
import ReportsIcon from '@material-ui/icons/ShowChart'
import GoalsIcon from '@material-ui/icons/Whatshot'
import SettingsIcon from '@material-ui/icons/Settings'
import ImportIcon from '@material-ui/icons/Publish'
import { Link, NavLink } from 'react-router-dom'
import Logo from 'components/logo/LogoWithName'
import SignOutMenuItem from 'components/signOut/SignOutMenuItem'
import withRoles from 'components/withRoles/withRoles'
import roles from 'components/users/roles'
import { withSubscription } from 'components/withSubscription/withSubscription'
import {
  DRAWER_WIDTH,
  MONTH_FORMAT,
  SUBSCRIPTION_STATUS
} from './../../constants'

const WithSubscriptionMenuItem = withSubscription(MenuItem, [
  SUBSCRIPTION_STATUS.ACTIVE,
  SUBSCRIPTION_STATUS.TRIALING
])

const WithRolesMenuItem = withRoles(MenuItem, [roles.OWNER, roles.EDITOR])

/* istanbul ignore next */
const styles = theme => ({
  drawerHeader: Object.assign({}, theme.mixins.toolbar, {
    height: '56px',
    display: 'flex',
    justifyContent: 'center'
  }),
  drawerDocked: {
    height: '100%'
  },
  drawerPaper: {
    width: 250,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      position: 'relative',
      height: '100%'
    }
  },
  itemSelected: {
    backgroundColor: theme.palette.action.selected
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }
})

// https://material-ui.com/guides/composition/#caveat-with-inlining
export const MenuItemLink = React.forwardRef(({ classes, ...rest }, ref) => (
  <NavLink activeClassName={classes.itemSelected} {...rest} innerRef={ref} />
))

const StyledMenuItemLink = withStyles(styles)(MenuItemLink)

export const ResponsiveDrawer = ({
  classes,
  active,
  onClose,
  month,
  location
}) => {
  const drawer = (
    <nav>
      <section className={classes.drawerHeader}>
        <Link to="/" className={classes.logoLink} onClick={onClose}>
          <Logo width="100%" />
        </Link>
      </section>
      <Divider />
      <MenuList>
        <MenuItem
          data-cy="nav-budget"
          classes={{ selected: classes.itemSelected }}
          className={`nav--budget`}
          component={
            /* istanbul ignore next */
            React.forwardRef((props, ref) => {
              const isActive = (match, location) => {
                const month = location.pathname.split('/')[1]
                const re = pathToRegExp(
                  '/:month/(add-transaction|shuffle-money)?'
                )
                return (
                  (match && match.isExact) ||
                  (!!re.exec(location.pathname) &&
                    moment(month, MONTH_FORMAT).isValid())
                )
              }
              return (
                <StyledMenuItemLink isActive={isActive} ref={ref} {...props} />
              )
            })
          }
          to={`/${month}`}
          onClick={onClose}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </MenuItem>
        <Divider />
        <MenuItem
          data-cy="nav-envelopes"
          classes={{ selected: classes.itemSelected }}
          className={`nav--envelopes`}
          component={StyledMenuItemLink}
          to={`/${month}/envelopes`}
          onClick={onClose}
        >
          <ListItemIcon>
            <EnvelopeIcon />
          </ListItemIcon>
          <ListItemText primary="Envelopes" />
        </MenuItem>
        <MenuItem
          data-cy="nav-goals"
          classes={{ selected: classes.itemSelected }}
          component={StyledMenuItemLink}
          to={`/${month}/goals`}
          onClick={onClose}
        >
          <ListItemIcon>
            <GoalsIcon />
          </ListItemIcon>
          <ListItemText primary="Goals" />
        </MenuItem>
        <MenuItem
          classes={{ selected: classes.itemSelected }}
          component={StyledMenuItemLink}
          to={`/reports`}
          onClick={onClose}
        >
          <ListItemIcon>
            <ReportsIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </MenuItem>
        <MenuItem
          data-cy="nav-transactions"
          classes={{ selected: classes.itemSelected }}
          className={`nav--transactions`}
          component={StyledMenuItemLink}
          to={`/${month}/transactions`}
          onClick={onClose}
        >
          <ListItemIcon>
            <TransactionsIcon />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </MenuItem>
        <Divider />
        <MenuItem
          data-cy="nav-accounts"
          classes={{ selected: classes.itemSelected }}
          className={`nav--accounts`}
          component={StyledMenuItemLink}
          to={`/${month}/accounts`}
          onClick={onClose}
        >
          <ListItemIcon>
            <AccountIcon />
          </ListItemIcon>
          <ListItemText primary="Accounts" />
        </MenuItem>
        <MenuItem
          data-cy="nav-budgets"
          classes={{ selected: classes.itemSelected }}
          className={`nav--budgets`}
          component={
            /* istanbul ignore next */
            React.forwardRef((props, ref) => {
              const isActive = (match, location) => {
                const re = pathToRegExp('/budgets/(add-budget|edit-budget)?')
                return !!re.exec(location.pathname)
              }
              return (
                <StyledMenuItemLink isActive={isActive} ref={ref} {...props} />
              )
            })
          }
          to="/budgets"
          onClick={onClose}
        >
          <ListItemIcon>
            <BudgetIcon />
          </ListItemIcon>
          <ListItemText primary="Budgets" />
        </MenuItem>
        <WithRolesMenuItem
          data-cy="import-transactions"
          button
          classes={{ selected: classes.itemSelected }}
          component={StyledMenuItemLink}
          to="/import-transactions"
          onClick={onClose}
        >
          <ListItemIcon>
            <ImportIcon />
          </ListItemIcon>
          <ListItemText primary="Import" />
        </WithRolesMenuItem>
        <WithSubscriptionMenuItem
          data-cy="nav-settings"
          button
          classes={{ selected: classes.itemSelected }}
          component={StyledMenuItemLink}
          to="/settings"
          onClick={onClose}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </WithSubscriptionMenuItem>
        <SignOutMenuItem onClick={onClose} />
      </MenuList>
    </nav>
  )

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={active}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={onClose}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          open
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  )
}

const Container = withRouter(withStyles(styles)(ResponsiveDrawer))

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    month: state.month
  }
}

export default connect(mapStateToProps, null)(Container)
