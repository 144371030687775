/* istanbul ignore file */
import {
  SET_BUDGETS,
  ADD_BUDGET,
  UPDATE_BUDGET,
  DELETE_BUDGET,
  SET_ACTIVE_BUDGET
} from './actionTypes'

export const setBudgets = items => {
  return {
    type: SET_BUDGETS,
    items
  }
}
export const addBudget = item => {
  return {
    type: ADD_BUDGET,
    item
  }
}
export const updateBudget = item => {
  return {
    type: UPDATE_BUDGET,
    item
  }
}
export const deleteBudget = item => {
  return {
    type: DELETE_BUDGET,
    item
  }
}
export const setActiveBudget = item => {
  return {
    type: SET_ACTIVE_BUDGET,
    item
  }
}
