import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { THEMES } from 'components/theme/themes'
import { ReactComponent as Logo } from 'components/logo/logoName.svg'
import { ReactComponent as LogoLight } from 'components/logo/logoNameLight.svg'
import { APP_NAME } from './../../constants'

export const LogoWithName = ({ theme, ...rest }) => {
  const muiTheme = useTheme()
  const _theme = theme || muiTheme
  return _theme === THEMES.LIGHT ? <Logo {...rest} /> : <LogoLight {...rest} />
}

LogoWithName.defaultProps = {
  'data-cy': 'logoWithName',
  role: 'img',
  height: '250',
  title: APP_NAME,
  width: '330'
}

export default LogoWithName
