import React from 'react'
import { withStyles } from '@material-ui/styles'
import Avatar from '@material-ui/core/Avatar'
import { getGravatarURL } from './../../util'

/* istanbul ignore next */
const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.light
  }
})

export const AvatarComponent = React.forwardRef(
  ({ classes, user, ...rest }, ref) => {
    const photoURL = user.photoURL || getGravatarURL(user.email)
    return (
      <Avatar
        alt={user.email}
        className={classes.avatar}
        ref={ref}
        src={photoURL}
        {...rest}
      />
    )
  }
)
export default withStyles(styles)(AvatarComponent)
