import React from 'react'
import { connect } from 'react-redux'
import { setMonth } from './actions'

export class Month extends React.Component {
  static defaultProps = {
    setMonth: setMonth
  }
  _setMonth = () => {
    const { month, reduxMonth, setMonth } = this.props
    if (month !== reduxMonth) {
      setMonth(month)
    }
  }
  componentDidMount() {
    this._setMonth()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this._setMonth()
  }
  render() {
    return null
  }
}
/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    reduxMonth: state.month
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setMonth: item => dispatch(setMonth(item))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Month)
