/* istanbul ignore file */
import { SET_GOALS, ADD_GOAL, UPDATE_GOAL, DELETE_GOAL } from './actionTypes'

export const setGoals = items => {
  return {
    type: SET_GOALS,
    items
  }
}
export const addGoal = item => {
  return {
    type: ADD_GOAL,
    item
  }
}
export const updateGoal = item => {
  return {
    type: UPDATE_GOAL,
    item
  }
}
export const deleteGoal = item => {
  return {
    type: DELETE_GOAL,
    item
  }
}
