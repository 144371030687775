import userApi from 'api/user/user'
import { AUTH_PROVIDERS } from 'api/firebase/firebase'

export const directSignUp = ({ provider, history }) => {
  if (!provider || !Object.keys(AUTH_PROVIDERS).includes(provider)) {
    return
  }
  userApi.signInWithProvider(provider)
  history.replace('/sign-up')
}
export default directSignUp
