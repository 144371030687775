import { get } from 'lodash'
import userApi from 'api/user/user'
import { isEmail } from './../../util'

export const getOwnerId = budget => {
  const roleKeys = Object.keys(get(budget, 'roles', {}))
  return roleKeys.find(key => budget.roles[key].type === 'owner')
}

// return budget users that are not the current user
export const getOtherUsers = (budget, currentUserId) => {
  let promises = []
  // all roles keys that are not the user i.e. shared users
  const roleKeys = Object.keys(get(budget, 'roles', {})).filter(
    roleKey => roleKey !== currentUserId
  )
  // the roleKey will either be a UID or an email
  roleKeys.forEach(roleKey => {
    const promise = isEmail(roleKey)
      ? { id: roleKey, email: roleKey }
      : userApi
          .get({ id: roleKey })
          .then(doc => ({ id: roleKey, ...doc.data() }))
    promises.push(promise)
  })
  return Promise.all(promises)
}

// given a budget return the users it has been shared with
export const getSharedUsers = budget => {
  let promises = []
  const ownerId = getOwnerId(budget)
  // all roles keys that are not the user i.e. shared users
  const roleKeys = Object.keys(get(budget, 'roles', {})).filter(
    roleKey => roleKey !== ownerId
  )
  // the roleKey will either be a UID or an email
  roleKeys.forEach(roleKey => {
    const promise = isEmail(roleKey)
      ? { id: roleKey, email: roleKey }
      : userApi
          .get({ id: roleKey })
          .then(doc => ({ id: roleKey, ...doc.data() }))
    promises.push(promise)
  })
  return Promise.all(promises)
}

export const getOwner = budget => {
  const ownerId = getOwnerId(budget)
  return isEmail(ownerId)
    ? { id: ownerId, email: ownerId }
    : userApi.get({ id: ownerId }).then(doc => ({ id: ownerId, ...doc.data() }))
}
