import {
  SET_BUDGETS,
  ADD_BUDGET,
  UPDATE_BUDGET,
  DELETE_BUDGET,
  SET_ACTIVE_BUDGET
} from './actionTypes'

const initialState = { activeBudget: undefined, items: [], loaded: false }

const items = (state = initialState, action) => {
  let items
  switch (action.type) {
    case SET_BUDGETS:
      return Object.assign({}, state, { items: action.items, loaded: true })
    case ADD_BUDGET:
      items = state.items.concat(action.item)
      return Object.assign({}, state, { items, loaded: true })
    case UPDATE_BUDGET:
      items = state.items.map(t => {
        if (t.id === action.item.id) {
          t = action.item
        }
        return t
      })
      return Object.assign({}, state, { items, loaded: true })
    case DELETE_BUDGET:
      items = state.items.filter(t => t.id !== action.item.id)
      return Object.assign({}, state, { items })
    case SET_ACTIVE_BUDGET:
      return Object.assign({}, state, {
        activeBudget: action.item,
        loaded: true
      })
    default:
      return state
  }
}

export default items
