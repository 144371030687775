/* istanbul ignore file */
import { get } from 'lodash'
import { combineLatest } from 'rxjs'
import { collectionData } from 'rxfire/firestore'
import { analytics as firebaseAnalytics, db } from 'api/firebase/firebase'
import analytics from './../../analytics'
import { SUBSCRIPTION_STATUS } from './../../constants'

const withId = (db, id, item) => {
  return id
    ? db.doc(`budgets/${id}`).set(item)
    : db.collection(`budgets`).add(item)
}

const logAnalytics = method => {
  analytics.event({
    category: 'budget',
    action: method,
    label: method
  })
  firebaseAnalytics.logEvent('budget', { action: method })
}

class Api {
  create(item, user) {
    const id = item.id
    delete item.id
    item = Object.assign({}, item, {
      roles: {
        [user.uid || user.id]: {
          hasRole: true,
          type: 'owner',
          status: get(user, 'subscription.status')
        }
      }
    })
    return withId(db, id, item).then(
      doc => {
        logAnalytics('create')
        return doc
      },
      e => {
        console.error(e)
        throw e
      }
    )
  }
  delete(item) {
    // NOTE: deleting a collection in the web client is not recommended
    // NOTE: There is an onBudgetDelete cloud function that will delete
    //   categories and transactions subcollections
    return db
      .doc(`budgets/${item.id}`)
      .delete()
      .then(
        doc => {
          logAnalytics('delete')
          return doc
        },
        e => {
          console.error(e)
          throw e
        }
      )
  }
  listen(firebaseUser, onChange = () => {}, onError = () => {}) {
    // INFO: using RxFire to combine two collection to form an `OR` query
    // https://firebase.googleblog.com/2018/09/introducing-rxfire-easy-async-firebase.html
    // https://github.com/firebase/firebase-js-sdk/tree/master/packages/rxfire
    const activeSubscriptionBudgets$ = collectionData(
      db
        .collection('budgets')
        .where(`roles.${firebaseUser.uid}.hasRole`, '==', true)
        .where(
          `roles.${firebaseUser.uid}.status`,
          '==',
          SUBSCRIPTION_STATUS.ACTIVE
        ),
      'id'
    )

    const trialingSubscriptionBudgets$ = collectionData(
      db
        .collection('budgets')
        .where(`roles.${firebaseUser.uid}.hasRole`, '==', true)
        .where(
          `roles.${firebaseUser.uid}.status`,
          '==',
          SUBSCRIPTION_STATUS.TRIALING
        ),
      'id'
    )
    return combineLatest(
      activeSubscriptionBudgets$,
      trialingSubscriptionBudgets$,
      (activeSubscriptionBudgets, trialingSubscriptionBudgets) => {
        return [...activeSubscriptionBudgets, ...trialingSubscriptionBudgets]
      }
    ).subscribe(
      budgets => onChange(budgets),
      e => onError(e)
    )
  }
  // get() {
  //   return new Promise((resolve, reject) => {
  //     resolve(mockData)
  //   })
  // }
  update(item, user) {
    const id = item.id
    delete item.id
    return db
      .doc(`budgets/${id}`)
      .update(item)
      .then(
        doc => {
          logAnalytics('update')
          return doc
        },
        e => {
          console.error(e)
          throw e
        }
      )
  }
}
const api = new Api()
export default api
