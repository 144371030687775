/* istanbul ignore file */
import { SET_USER, SET_USERS, SET_FIREBASE_USER } from './actionTypes'

export const setUser = user => {
  return {
    type: SET_USER,
    user
  }
}

export const setUsers = users => {
  return {
    type: SET_USERS,
    users
  }
}

export const setFirebaseUser = user => {
  return {
    type: SET_FIREBASE_USER,
    user
  }
}
