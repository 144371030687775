/* istanbul ignore file */
import {
  SET_ACCOUNTS,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT
} from './actionTypes'

export const setAccounts = items => {
  return {
    type: SET_ACCOUNTS,
    items
  }
}
export const addAccount = item => {
  return {
    type: ADD_ACCOUNT,
    item
  }
}
export const updateAccount = item => {
  return {
    type: UPDATE_ACCOUNT,
    item
  }
}
export const deleteAccount = item => {
  return {
    type: DELETE_ACCOUNT,
    item
  }
}
