import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { connect } from 'react-redux'
import userApi from './../../api/user/user'
import { setFirebaseUser } from './../../api/user/actions'

export const SignOut = ({ user, setFirebaseUser, onClick, ...rest }) => {
  const signOut = () => userApi.signOut().then(() => setFirebaseUser(null))
  return (
    <MenuItem
      onClick={() => {
        signOut()
        onClick()
      }}
      {...rest}
    >
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Sign out" />
    </MenuItem>
  )
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.firebaseUser
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFirebaseUser: user => dispatch(setFirebaseUser(user))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SignOut)
