import {
  SET_TRANSACTIONS,
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION
} from './actionTypes'

const initialState = { items: [] }

const items = (state = initialState, action) => {
  let items
  switch (action.type) {
    case SET_TRANSACTIONS:
      return { ...state, items: action.items }
    case ADD_TRANSACTION:
      items = state.items.concat(action.item)
      return { ...state, items }
    case UPDATE_TRANSACTION:
      items = state.items.map(t => {
        if (t.id === action.item.id) {
          t = action.item
        }
        return t
      })
      return { ...state, items }
    case DELETE_TRANSACTION:
      items = state.items.filter(t => t.id !== action.item.id)
      return { ...state, items }
    default:
      return state
  }
}

export default items
