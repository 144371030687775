import React from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

export default ({ action, handleClose, open }) => (
  <Snackbar open={open}>
    <SnackbarContent
      action={[
        <Button
          key="refresh"
          color="secondary"
          onClick={/* istanbul ignore next */ e => action()}
        >
          Refresh
        </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
      aria-describedby="message-id"
      message={
        <Typography color="inherit" id="message-id">
          New content is available
        </Typography>
      }
      style={{ flexWrap: 'nowrap' }}
    />
  </Snackbar>
)
