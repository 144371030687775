import {
  SET_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY
} from './actionTypes'

const initialState = { items: [] }

const items = (state = initialState, action) => {
  let items
  switch (action.type) {
    case SET_CATEGORIES:
      return { ...state, items: action.items }
    case ADD_CATEGORY:
      items = state.items.concat(action.item)
      return { ...state, items }
    case UPDATE_CATEGORY:
      items = state.items.map(t => {
        if (t.id === action.item.id) {
          t = action.item
        }
        return t
      })
      return { ...state, items }
    case DELETE_CATEGORY:
      items = state.items.filter(t => t.id !== action.item.id)
      return { ...state, items }
    default:
      return state
  }
}

export default items
