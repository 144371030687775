import React from 'react'
import { connect } from 'react-redux'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'

/* istanbul ignore file */

const ThemeProvider = ({ dispatch, theme, user, ...rest }) => (
  <MuiThemeProvider theme={theme} {...rest} />
)

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => ({
  theme: state.theme
})

export default connect(mapStateToProps, null)(ThemeProvider)
