import { SET_USER, SET_USERS, SET_FIREBASE_USER } from './actionTypes'

const user = (
  state = { user: null, users: [], firebaseUser: null },
  action
) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.user }
    case SET_USERS:
      return { ...state, users: action.users }
    case SET_FIREBASE_USER:
      return { ...state, firebaseUser: action.user }
    default:
      return state
  }
}

export default user
