import React from 'react'
import { get, memoize } from 'lodash'
import { connect } from 'react-redux'
import { db } from './firebase'
import { setCategories } from './../categories/actions'

// add top level `amount` to simplify property access later
// this is strickly a client property
const addAmount = (item, month) => {
  const amount = get(item, `months[${month}].amount`, '0')
  return { ...item, amount }
}
// add top level `archive` to simplify property access later
// this is strickly a client property
const addArchive = (item, month) => {
  const archive = get(item, `months[${month}].archive`, false)
  return { ...item, archive }
}

/* istanbul ignore next */
let unsubscribe = () => {}
/* istanbul ignore next */
const getRealtime = (ref, budget, month, setData) => {
  unsubscribe = ref.orderBy('name').onSnapshot(
    querySnapshot => {
      let items = []
      querySnapshot.forEach(doc => {
        let item = { id: doc.id, ...doc.data() }
        item = addAmount(item, month)
        item = addArchive(item, month)
        items.push(item)
      })
      setData(items)
    },
    err => {
      // This may occur if there are no available resources yet
      console.info('Categories Listener\n:', err)
      retry(ref, budget, month, setData)
    }
  )
}

/* istanbul ignore next */
const retry = memoize((ref, budget, month, setData) => {
  console.log('memoize attempt category listener')
  getRealtime(ref, budget, month, setData)
})

let _id
let _month
/* istanbul ignore next */
const Listener = ({ budget = {}, month, setData }) => {
  const id = budget.id
  if (!id || !month) return null
  if (_id === id && _month === month) return null
  unsubscribe()
  _id = id
  _month = month
  const ref = db.collection(`budgets/${id}/categories`)
  getRealtime(ref, budget, month, setData)
  return null
}

/* istanbul ignore next */
class Container extends React.Component {
  componentWillMount() {
    _id = null
    _month = null
  }
  componentWillUnmount() {
    unsubscribe()
  }
  render() {
    return <Listener {...this.props} />
  }
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    budget: state.budgets.activeBudget,
    month: state.month
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setData: items => dispatch(setCategories(items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
