import React from 'react'
import numeral from 'numeral'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import DoneIcon from '@material-ui/icons/Done'
import green from '@material-ui/core/colors/green'
import CheckoutForm from './CheckoutForm'
import ShufflePaper from './ShufflePaper'
import Logo from 'components/logo/LogoWithName'
import Scheduler from 'components/scheduler/Scheduler'
import userApi from 'api/user/user'
import { setFirebaseUser } from 'api/user/actions'
import { STRIPE_CHECKOUT } from './../../environments'
import { HELP_EMAIL, NUMBER_FORMAT } from './../../constants'
import THEMES from 'components/theme/themes'

/* istanbul ignore next */
const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: `${
        theme === THEMES.LIGHT
          ? theme.palette.primary.main
          : theme.palette.background.default
      } !important`
    }
  },
  subscribeGrid: {
    display: 'grid',
    gridGap: `${theme.spacing(2)}px`,
    gridTemplateAreas: `
        "header"
        "main"
        "sidebar"
        "footer"
      `,
    justifyContent: 'center',
    margin: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateAreas: `
        "header header header"
        "main main sidebar"
        "footer footer ."
      `
    }
  },
  paidGrid: {
    display: 'grid',
    gridGap: `${theme.spacing(2)}px`,
    gridTemplateAreas: `
        "header"
        "main"
        "sidebar"
        "footer"
      `,
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  logoSection: {
    gridArea: 'header',
    alignItems: 'center',
    display: 'flex',
    height: '96px',
    justifyContent: 'center'
  },
  subscribeSection: {
    gridArea: 'main',
    textAlign: 'center'
  },
  paidSection: {
    gridArea: 'main',
    textAlign: 'center'
  },
  consultSection: {
    gridArea: 'sidebar'
  },
  signOutSection: {
    gridArea: 'footer'
  },
  paper: {
    maxWidth: '24rem',
    padding: theme.spacing(2)
  },
  budgetConsultTypography: {
    fontSize: `calc(${theme.typography.h6.fontSize} / 1.25)`,
    textAlign: 'center'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  priceContent: {
    fontSize: `calc(${theme.typography.h6.fontSize} / 1.25)`,
    textAlign: 'center'
  }
})

export const BudgetConsult = ({ classes, ...rest }) => (
  <section {...rest}>
    <Paper className={classes.paper}>
      <header>
        <Typography className={classes.budgetConsultTypography} variant="h6">
          Get Started Right
        </Typography>
      </header>
      <Scheduler
        className={classes.content}
        color="primary"
        fullWidth={true}
        variant="contained"
      />
    </Paper>
  </section>
)
const StyledBudgetConsult = withStyles(styles)(BudgetConsult)

export const Paid = ({ classes, ...rest }) => (
  <section {...rest}>
    <Paper className={classes.paper}>
      <header>
        <Typography variant="h6">Thank you!</Typography>
      </header>
      <Typography className={classes.content}>
        Shuffling paper work...
      </Typography>
      <ShufflePaper />
      <Typography>We'll have you on your way in just a moment.</Typography>
    </Paper>
  </section>
)
const StyledPaid = withStyles(styles)(Paid)

export const Subscribe = ({ classes, onPaid, user, ...rest }) => {
  const benefits = [
    'Unlimited budgets',
    'Share with your partner or friend for accountability',
    'Track spending',
    'Zero based budget options',
    'Full reporting by dates and categories'
  ]
  return (
    <section {...rest}>
      <Paper className={classes.paper}>
        <header>
          <Typography variant="h6">Key Benefits</Typography>
        </header>
        <List dense={true} disablePadding={false}>
          {benefits.map(benefit => (
            <ListItem key={benefit}>
              <ListItemIcon>
                <DoneIcon style={{ fill: green[500] }} />
              </ListItemIcon>
              <ListItemText primary={benefit} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <Typography
          variant="h6"
          className={`${classes.content} ${classes.priceContent}`}
        >{`Annual Subscription  ${numeral(STRIPE_CHECKOUT.amount / 100).format(
          NUMBER_FORMAT
        )}`}</Typography>
        <CheckoutForm user={user} onPaid={onPaid} />
      </Paper>
    </section>
  )
}
const StyledSubscribe = withStyles(styles)(Subscribe)

export class Subscription extends React.Component {
  static defaultProps = { logo: true, onPaid() {} }
  state = {
    paid: false
  }
  signOut = () => {
    const { setFirebaseUser } = this.props
    return userApi.signOut().then(() => setFirebaseUser(null))
  }
  render() {
    const { classes, logo, onPaid, style, user } = this.props
    const { paid } = this.state
    return (
      <article
        className={`${paid ? classes.paidGrid : classes.subscribeGrid}`}
        data-cy="subscribe-page"
        style={style}
      >
        {logo && (
          <div className={classes.logoSection}>
            <Logo theme={THEMES.DARK} />
          </div>
        )}
        {!paid && (
          <StyledSubscribe
            className={classes.subscribeSection}
            user={user}
            onPaid={
              /* istanbul ignore next */
              () => {
                this.setState({ paid: true })
                onPaid()
              }
            }
          />
        )}
        {!paid && <StyledBudgetConsult className={classes.consultSection} />}
        {paid && <StyledPaid className={classes.paidSection} />}
        <section
          className={classes.signOutSection}
          style={{
            textAlign: paid ? 'center' : 'initial'
          }}
        >
          <Paper className={classes.paper}>
            <Typography className={classes.content}>
              We're here to help. Email us at{' '}
              <Link href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</Link> if you're
              having trouble.
            </Typography>
            <Button
              className={classes.content}
              fullWidth={true}
              onClick={this.signOut}
            >
              Sign Out
            </Button>
          </Paper>
        </section>
      </article>
    )
  }
}
const SubscriptionWithStyles = withStyles(styles)(Subscription)

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFirebaseUser: user => dispatch(setFirebaseUser(user))
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionWithStyles)
