import moment from 'moment'
import { SET_MONTH } from './actionTypes'
import { MONTH_FORMAT } from './../../constants'

const month = (state = moment().format(MONTH_FORMAT), action) => {
  switch (action.type) {
    case SET_MONTH:
      return action.month
    default:
      return state
  }
}

export default month
