import React from 'react'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow'
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh'
import Tooltip from '@material-ui/core/Tooltip'
import { setTheme } from 'components/theme/actions'
import { THEMES } from 'components/theme/themes'

export const ThemeSwitch = ({ setTheme, theme }) => {
  const THEME_DATA =
    theme === THEMES.LIGHT
      ? { theme: THEMES.DARK, title: 'Dark theme', Icon: BrightnessLowIcon }
      : { theme: THEMES.LIGHT, title: 'Light theme', Icon: BrightnessHighIcon }

  return (
    <Tooltip title={THEME_DATA.title}>
      <IconButton
        color="inherit"
        aria-label={THEME_DATA.title}
        onClick={() => setTheme(THEME_DATA.theme)}
      >
        <THEME_DATA.Icon />
      </IconButton>
    </Tooltip>
  )
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => ({
  setTheme: theme => dispatch(setTheme(theme))
})
const mapStateToProps = (state, ownProps) => ({
  theme: state.theme
})

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitch)
