import {
  ADD_NOTIFICATION,
  ADD_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATIONS_OF_TYPE
} from './actionTypes'

const initialState = { items: [] }

export default (state = initialState, action) => {
  let items
  switch (action.type) {
    case ADD_NOTIFICATION:
      items = state.items.concat(action.item)
      return { ...state, items }
    case ADD_NOTIFICATIONS:
      items = state.items.concat(action.items)
      return { ...state, items }
    case REMOVE_NOTIFICATION:
      items = state.items.filter(item => item.id !== action.item.id)
      return { ...state, items }
    case REMOVE_NOTIFICATIONS_OF_TYPE:
      items = state.items.filter(item => item.type !== action.itemType)
      return { ...state, items }
    default:
      return state
  }
}
