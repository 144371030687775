/* istanbul ignore file */
import firebase from 'firebase/app'
// Required for side-effects
import 'firebase/analytics'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { FIREBASE_CONFIG } from './../../environments'

firebase.initializeApp(FIREBASE_CONFIG)

export const analytics = firebase.analytics()
export const db = firebase.firestore()

// Offline persistence has limitations
// Check the documentation for latest info
// https://firebase.google.com/docs/firestore/manage-data/enable-offline
db.enablePersistence()
  .then(() => {})
  .catch(err => {
    let errorMessage = err
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a a time
      errorMessage =
        'Multiple tabs open, persistence can only be enabled in one tab at a a time'
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the features required to enable persistence
      errorMessage =
        'The current browser does not support all of the features required to enable persistence'
    }
    console.warn(errorMessage)
  })

export const auth = firebase.auth()

export const AUTH_PROVIDERS = {
  google: 'google',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github'
}

export const getAuthProvider = name => {
  switch (name) {
    case AUTH_PROVIDERS.facebook:
      return new firebase.auth.FacebookAuthProvider()
    case AUTH_PROVIDERS.github:
      return new firebase.auth.GithubAuthProvider()
    case AUTH_PROVIDERS.twitter:
      return new firebase.auth.TwitterAuthProvider()
    case AUTH_PROVIDERS.google:
    default:
      return new firebase.auth.GoogleAuthProvider()
  }
}
export default firebase
