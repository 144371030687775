import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import BudgetIcon from '@material-ui/icons/AttachMoney'
import AccountIcon from '@material-ui/icons/AccountBalance'
import EnvelopeIcon from '@material-ui/icons/Mail'
import GoalsIcon from '@material-ui/icons/Whatshot'
import TransactionsIcon from '@material-ui/icons/SwapHoriz'
import SettingsIcon from '@material-ui/icons/Settings'

// these are using `render` in place of `component` to prevent
// an Unknown staticContext prop error.
// https://github.com/ReactTraining/react-router/issues/4683
export default () => (
  <Switch>
    <Route
      path="/:month/envelopes"
      render={
        /* istanbul ignore next */
        ({ staticContext, ...props }) => <EnvelopeIcon {...props} />
      }
    />
    <Route
      path="/:month/accounts"
      render={
        /* istanbul ignore next */
        ({ staticContext, ...props }) => <AccountIcon {...props} />
      }
    />
    <Route
      path="/:month/goals"
      render={
        /* istanbul ignore next */
        ({ staticContext, ...props }) => <GoalsIcon {...props} />
      }
    />
    <Route
      path="/:month/transactions"
      render={
        /* istanbul ignore next */
        ({ staticContext, ...props }) => <TransactionsIcon {...props} />
      }
    />
    <Route
      path="/budgets/(add-budget|edit-budget)?"
      render={
        /* istanbul ignore next */
        ({ staticContext, ...props }) => <BudgetIcon {...props} />
      }
    />
    <Route
      path="/settings"
      render={
        /* istanbul ignore next */
        ({ staticContext, ...props }) => <SettingsIcon {...props} />
      }
    />
    <Route
      path="/:month"
      render={
        /* istanbul ignore next */
        ({ staticContext, ...props }) => <HomeIcon {...props} />
      }
    />
  </Switch>
)
