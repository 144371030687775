/* istanbul ignore file */
import {
  analytics as firebaseAnalytics,
  auth,
  db,
  getAuthProvider
} from 'api/firebase/firebase'
import { APP_URL } from './../../constants'
import analytics from '../../analytics'

const UnVerifiedEmailError = {
  code: 'auth/email-not-verified',
  message: 'Email not verified'
}

const logAnalytics = (method, label = '') => {
  analytics.event({
    category: 'login',
    action: method,
    label: label
  })
  firebaseAnalytics.logEvent('login', { method, status: label })
}

class User {
  get(params) {
    return db
      .doc(`users/${params.id}`)
      .get()
      .then(
        response => response,
        e => {
          console.error(e)
          throw e
        }
      )
  }
  getAll() {
    return db
      .collection('users')
      .get()
      .then(
        response => response,
        e => {
          console.error(e)
          throw e
        }
      )
  }
  delete(user) {
    // NOTE: deleting a user will delete all related user data
    //  - customer data
    //  - budget data
    //  - firebase authentication data
    //  - stripe customer & subscription data
    return db
      .doc(`users/${user.id}`)
      .delete()
      .then(
        () => {},
        e => {
          console.error(e)
          throw e
        }
      )
  }
  update(params) {
    const id = params.id
    delete params.id
    return db
      .doc(`users/${id}`)
      .update(params)
      .then(
        response => response,
        e => {
          console.error(e)
          throw e
        }
      )
  }
  listen(firebaseUser, onChange = () => {}, onError = () => {}) {
    return db.doc(`users/${firebaseUser.uid}`).onSnapshot(
      doc => onChange(doc),
      e => onError(e)
    )
  }
  listenAll(firebaseUser, onChange = () => {}) {
    return db
      .collection('users')
      .onSnapshot(querySnapshot => onChange(querySnapshot))
  }
  listenToFirebaseUser(onChange = () => {}) {
    return auth.onAuthStateChanged(
      user => onChange(user),
      e => {
        console.error(e)
        throw e
      }
    )
  }
  fetchSignInMethodsForEmail(email) {
    return auth.fetchSignInMethodsForEmail(email)
  }
  getRedirectResult() {
    return auth.getRedirectResult()
  }
  signInWithProvider(provider) {
    const authProvider = getAuthProvider(provider)
    // returns firebase.Promise containing non-null firebase.auth.UserCredential or error codes
    // return auth.signInWithPopup(authProvider)

    logAnalytics(provider, 'start')
    // returns firebase.Promise containing void
    return auth.signInWithRedirect(authProvider).then(
      () => logAnalytics(provider, 'success'),
      e => {
        logAnalytics(provider, 'fail')
        return Promise.reject(e)
      }
    )
  }
  signInWithEmailAndPassword(email, password) {
    logAnalytics('email', 'start')
    return auth.signInWithEmailAndPassword(email, password).then(
      user => {
        if (!user.emailVerified) {
          logAnalytics('email', 'fail:UnVerifiedEmailError')
          throw UnVerifiedEmailError
        }
        logAnalytics('email', 'success')
        return user
      },
      e => {
        logAnalytics('email', 'fail')
        return Promise.reject(e)
      }
    )
  }
  createUserWithEmailAndPassword(email, password) {
    logAnalytics('email', 'create')
    return auth.createUserWithEmailAndPassword(email, password).then(
      user => {
        logAnalytics('email', 'create:success')
        return user
      },
      e => {
        logAnalytics('email', 'create:fail')
        return Promise.reject(e)
      }
    )
  }
  sendEmailVerification() {
    // use action code setting to redirect back to app after email verification
    // https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
    let actionCodeSettings = { url: APP_URL }
    return auth.currentUser.sendEmailVerification(actionCodeSettings)
  }
  sendEmailVerificationNoContinue() {
    return auth.currentUser.sendEmailVerification()
  }
  sendPasswordResetEmail(email) {
    logAnalytics('email', 'pasword reset')
    return auth.sendPasswordResetEmail(email)
  }
  signOut() {
    logAnalytics('signout', 'start')
    return auth.signOut().then(
      () => logAnalytics('signout', 'success'),
      e => {
        logAnalytics('signout', 'fail')
        return Promise.reject(e)
      }
    )
  }
}

const user = new User()
export default user
