import React from 'react'
import { connect } from 'react-redux'

export function withSubscription(WrappedComponent, statuses = [], props = {}) {
  function WithSubscription({
    user,
    // unused redux prop
    dispatch,
    ...rest
  }) {
    const status = user?.subscription?.status

    return statuses.includes(status) ? (
      <WrappedComponent user={user} {...rest} />
    ) : (
      // this is in a span to accommodate material-ui tooltips
      // https://material-ui.com/demos/tooltips/#disabled-elements
      <span>
        {props.hide ? null : (
          <WrappedComponent user={user} {...rest} disabled />
        )}
      </span>
    )
  }
  /* istanbul ignore next */
  const mapStateToProps = (state, ownProps) => {
    return {
      user: state.user.user
    }
  }
  WithSubscription.displayName = `WithSubscription(${getDisplayName(
    WrappedComponent
  )})`
  return connect(mapStateToProps, null)(WithSubscription)
}

function getDisplayName(WrappedComponent) {
  /* istanbul ignore next */
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withSubscription
