import React from 'react'
import { get } from 'lodash'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import EmailIcon from '@material-ui/icons/Email'
import GoogleIcon from './GoogleIcon'
import FacebookIcon from './FacebookIcon'
// import TwitterIcon from './TwitterIcon'
// import GithubIcon from './GithubIcon'
import userApi from './../../api/user/user'
import { AUTH_PROVIDERS } from './../../api/firebase/firebase'

/* istanbul ignore next */
const styles = theme => ({
  button: {
    margin: `${theme.spacing()}px 0`,
    justifyContent: 'flex-start'
  },
  iconButton: {
    '& svg': {
      height: '24px',
      width: '24px'
    }
  },
  googleButton: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey['200']
    }
  },
  facebookButton: {
    color: theme.palette.common.white,
    backgroundColor: 'rgb(66, 103, 178)',
    '&:hover': {
      backgroundColor: 'rgb(53,82,142)'
    }
  },
  emailButton: {
    color: theme.palette.common.white,
    backgroundColor: '#db4437',
    '&:hover': {
      backgroundColor: ' #af362c'
    }
  },
  icon: {
    marginRight: theme.spacing()
  }
})

/* istanbul ignore next */
const signIn = (userApi, provider) => userApi.signInWithProvider(provider)

export class Options extends React.Component {
  _redirectResultFailed = error => {
    const email = get(error, 'email')
    let message = `Unable to authenticate`
    let promises = []

    if (error.code === 'auth/account-exists-with-different-credential') {
      message = 'Must use prior sign in method'
      promises.push(userApi.fetchSignInMethodsForEmail(email))
    }
    if (error.code === 'auth/operation-not-allowed') {
      message = 'Authentication method is disabled'
    }

    promises.unshift(Promise.resolve({ error, message }))
    return Promise.all(promises)
  }
  _signInWithExistingCredentials = (results, onError) => {
    if (!results) return
    const { error, message } = results[0]
    const method = get(results[1], '0')
    let snackbarAction
    let snackbarActionText

    if (error.code !== 'auth/account-exists-with-different-credential') {
      return onError({ error, message })
    }

    // method will be in the form of <provider>.com i.e. facebook.com
    // we'll compare that against our AUTH_PROVIDERS which will
    // be in the form of <provider> i.e. facebook
    switch (true) {
      // case 'facebook.com':
      case !!method.match(AUTH_PROVIDERS.facebook):
        snackbarAction = /* istanbul ignore next */ () =>
          signIn(userApi, AUTH_PROVIDERS.facebook)
        snackbarActionText = AUTH_PROVIDERS.facebook
        break
      case !!method.match(AUTH_PROVIDERS.github):
        snackbarAction = /* istanbul ignore next */ () =>
          signIn(userApi, AUTH_PROVIDERS.github)
        snackbarActionText = AUTH_PROVIDERS.github
        break
      case !!method.match(AUTH_PROVIDERS.twitter):
        snackbarAction = /* istanbul ignore next */ () =>
          signIn(userApi, AUTH_PROVIDERS.twitter)
        snackbarActionText = AUTH_PROVIDERS.twitter
        break
      case !!method.match(AUTH_PROVIDERS.google):
      default:
        snackbarAction = /* istanbul ignore next */ () =>
          signIn(userApi, AUTH_PROVIDERS.google)
        snackbarActionText = AUTH_PROVIDERS.google
    }
    return onError({ error, message, snackbarAction, snackbarActionText })
  }
  componentDidMount() {
    const { onError, userApi } = this.props
    userApi
      .getRedirectResult()
      .then(
        ({ user }) => undefined,
        /* istanbul ignore next */
        error => this._redirectResultFailed(error)
      )
      .then(
        results => this._signInWithExistingCredentials(results, onError),
        /* istanbul ignore next */
        e => {
          console.warn('Failed to fetchSignInMethodsForEmail', e)
        }
      )
  }
  render() {
    const { classes, buttonText, onEmailClick, userApi } = this.props
    return (
      <React.Fragment>
        <Button
          data-options="google"
          className={`${classes.button} ${classes.iconButton} ${classes.googleButton}`}
          onClick={
            /* istanbul ignore next */
            e => signIn(userApi, AUTH_PROVIDERS.google)
          }
          variant="contained"
        >
          <GoogleIcon className={classes.icon} />
          {`${buttonText} ${AUTH_PROVIDERS.google}`}
        </Button>
        <Button
          data-options="facebook"
          className={`${classes.button} ${classes.iconButton} ${classes.facebookButton}`}
          onClick={
            /* istanbul ignore next */
            e => signIn(userApi, AUTH_PROVIDERS.facebook)
          }
          variant="contained"
        >
          <FacebookIcon className={classes.icon} />
          {`${buttonText} ${AUTH_PROVIDERS.facebook}`}
        </Button>
        <Button
          data-options="email"
          className={`${classes.button} ${classes.emailButton}`}
          onClick={onEmailClick}
          variant="contained"
        >
          <EmailIcon className={classes.icon} />
          {`${buttonText} email`}
        </Button>
      </React.Fragment>
    )
  }
}

Options.defaultProps = {
  buttonText: 'Sign in with',
  onEmailClick: /* istanbul ignore next */ () => undefined,
  onError: /* istanbul ignore next */ () => undefined,
  userApi
}

export default withStyles(styles)(Options)
