import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'
import withRoles from './../withRoles/withRoles'
import roles from './../users/roles'

const NotificationsIconButton = ({
  disabled,
  dispatch,
  location,
  notifications,
  staticContext,
  ...rest
}) => (
  <IconButton
    aria-label="show notifications"
    component={Link}
    disabled={disabled}
    to={`${location.pathname}/notifications`}
    {...rest}
  >
    <Badge
      badgeContent={notifications.length}
      color={disabled ? 'default' : 'secondary'}
    >
      <NotificationsIcon />
    </Badge>
  </IconButton>
)

const WithRolesNotificationsIconButton = withRoles(NotificationsIconButton, [
  roles.OWNER,
  roles.EDITOR
])

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifications.items
  }
}

export default withRouter(
  connect(mapStateToProps, null)(WithRolesNotificationsIconButton)
)
