import {
  SET_ACCOUNTS,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT
} from './actionTypes'

const initialState = { items: [] }

const items = (state = initialState, action) => {
  let items
  switch (action.type) {
    case SET_ACCOUNTS:
      return { ...state, items: action.items }
    case ADD_ACCOUNT:
      items = state.items.concat(action.item)
      return { ...state, items }
    case UPDATE_ACCOUNT:
      items = state.items.map(t => {
        if (t.id === action.item.id) {
          t = action.item
        }
        return t
      })
      return { ...state, items }
    case DELETE_ACCOUNT:
      items = state.items.filter(t => t.id !== action.item.id)
      return { ...state, items }
    default:
      return state
  }
}

export default items
