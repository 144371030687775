import React from 'react'
import URLSearchParams from 'url-search-params'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import Slide from '@material-ui/core/Slide'
import { Link } from 'react-router-dom'
import Logo from 'components/logo/LogoWithName'
import Options from './Options'
import Email from './Email'
import CloseableSnackbar from './CloseableSnackbar'
import userApi from 'api/user/user'
import { isEmail } from './../../util'

/* istanbul ignore next */
const styles = theme => ({
  article: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh'
  },
  actionSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column'
  },
  formSection: {
    flex: 1,
    overflow: 'hidden',
    minWidth: '375px',
    maxWidth: '736px',
    width: '100%'
  },
  logoMask: {
    display: 'flex',
    alignItems: 'center',
    height: '100px'
  }
})

const defaultFields = {
  email: new URLSearchParams(window.location.search).get('email') || '',
  password: ''
}

export const EmailMessage = () => (
  <span>
    User not found <Link to="/sign-up">sign up</Link>
  </span>
)

export class Login extends React.Component {
  state = {
    fields: { ...defaultFields, ...this.props.fields },
    fieldErrors: {},
    snackbarActive: false,
    snackbarAction: false,
    snackbarActionText: '',
    snackbarMessage: '',
    showEmail: false,
    validated: true
  }
  _getErrors = ({ e, fieldErrors }) => {
    let errors = { ...fieldErrors }
    switch (e.code) {
      case 'auth/invalid-email':
        errors.email = 'Invalid email'
        break
      case 'auth/user-not-found':
        errors.email = <EmailMessage />
        break
      case 'auth/email-not-verified':
        errors.email = 'Email not verified'
        break
      case 'auth/wrong-password':
        errors.password = 'Invalid password'
        break
      default:
        errors.email = e.message
        break
    }
    return errors
  }
  _handleFailedPasswordReset = ({ e, fieldErrors }) => {
    const errors = this._getErrors({ e, fieldErrors })
    this.setState({ fieldErrors: errors })
  }
  _handleFailedSignIn = ({ e, fieldErrors }) => {
    const errors = this._getErrors({ e, fieldErrors })
    this.setState({ fieldErrors: errors })
  }
  onForgotPassword = ({ e, fields, fieldErrors }) => {
    return userApi.sendPasswordResetEmail(fields.email).then(
      () => {
        this.setState({
          fieldErrors: {},
          snackbarActive: true,
          snackbarAction:
            /* istanbul ignore next */
            () => userApi.sendPasswordResetEmail(fields.email),
          snackbarActionText: 'Resend email',
          snackbarMessage: `Reset password was sent to ${fields.email}`
        })
      },
      /* istanbul ignore next */
      e => this._handleFailedPasswordReset({ e, fieldErrors })
    )
  }
  onSignIn = ({ e, fields, fieldErrors }) => {
    e.preventDefault()
    userApi.signInWithEmailAndPassword(fields.email, fields.password).then(
      user => undefined,
      /* istanbul ignore next */
      e => this._handleFailedSignIn({ e, fieldErrors })
    )
  }
  render() {
    const { classes } = this.props
    const {
      fields,
      fieldErrors,
      snackbarActive,
      snackbarAction,
      snackbarActionText,
      snackbarMessage,
      showEmail,
      validated
    } = this.state
    return (
      <article className={classes.article}>
        <section className={classes.actionSection}>
          <header className={classes.logoMask}>
            <Logo />
          </header>
          <div className={classes.actionButtons} data-cy="login-form">
            <Options
              onEmailClick={
                /* istanbul ignore next */
                () => this.setState({ showEmail: !showEmail })
              }
              onError={
                /* istanbul ignore next */
                ({ message, snackbarAction, snackbarActionText }) =>
                  this.setState({
                    snackbarActive: true,
                    snackbarMessage: message,
                    snackbarAction,
                    snackbarActionText
                  })
              }
            />
            <Button
              component={Link}
              to="/sign-up"
              color="primary"
              data-cy="do-not-have-account"
            >
              Don't have an account?
            </Button>
          </div>
        </section>
        <section className={classes.formSection}>
          <Slide direction="up" in={showEmail}>
            <Email
              fields={fields}
              fieldErrors={fieldErrors}
              onInputChange={
                /* istanbul ignore next */
                ({ fields, fieldErrors }) =>
                  this.setState({ fields, fieldErrors })
              }
              onValidate={
                /* istanbul ignore next */
                valid => {
                  if (valid === validated) return this
                  this.setState({ validated: valid })
                }
              }
              actions={[
                <Button
                  key={0}
                  fullWidth={true}
                  type="submit"
                  color="primary"
                  disabled={validated}
                  onClick={
                    /* istanbul ignore next */
                    e => this.onSignIn({ e, fields, fieldErrors })
                  }
                >
                  Sign In
                </Button>,
                <Button
                  key={1}
                  fullWidth={true}
                  color="primary"
                  disabled={!fields.email || !isEmail(fields.email)}
                  onClick={
                    /* istanbul ignore next */
                    e => this.onForgotPassword({ e, fields, fieldErrors })
                  }
                >
                  Forgot Password
                </Button>
              ]}
            />
          </Slide>
        </section>
        <CloseableSnackbar
          open={snackbarActive}
          action={snackbarAction}
          actionText={snackbarActionText}
          message={snackbarMessage}
          handleClose={
            /* istanbul ignore next */
            () => {
              this.setState({
                snackbarActive: false,
                snackbarAction: false,
                snackbarMessage: ''
              })
            }
          }
        />
      </article>
    )
  }
}

export default withStyles(styles)(Login)
