import React from 'react'
import { get, isEqual } from 'lodash'
import { connect } from 'react-redux'
import { setActiveBudget } from './../budgets/actions'
import userApi from './../user/user'

export class Listener extends React.Component {
  static defaultProps = { userApi }
  _setActiveBudget = () => {
    const { budgets, setActiveBudget, user, userApi } = this.props
    const activeBudget = budgets.find(
      budget => budget.id === user.activeBudgetId
    )
    if (!activeBudget && budgets[0]) {
      // set a default active budget
      userApi.update({ id: user.id, activeBudgetId: budgets[0].id })
    }
    setActiveBudget(activeBudget)
  }
  componentDidMount() {
    this._setActiveBudget()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { budgets, user } = this.props
    const prevActiveBudgetId = get(prevProps, 'user.activeBudgetId')
    const activeBudgetIdChanged = prevActiveBudgetId !== user.activeBudgetId
    const budgetsChanged = !isEqual(prevProps.budgets, budgets)

    if (activeBudgetIdChanged || budgetsChanged) {
      this._setActiveBudget()
    }
  }
  render() {
    return null
  }
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    budgets: state.budgets.items,
    user: state.user.user
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setActiveBudget: item => dispatch(setActiveBudget(item))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listener)
