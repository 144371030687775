/* istanbul ignore file */
import {
  SET_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY
} from './actionTypes'

export const setCategories = items => {
  return {
    type: SET_CATEGORIES,
    items
  }
}
export const addCategory = item => {
  return {
    type: ADD_CATEGORY,
    item
  }
}
export const updateCategory = item => {
  return {
    type: UPDATE_CATEGORY,
    item
  }
}
export const deleteCategory = item => {
  return {
    type: DELETE_CATEGORY,
    item
  }
}
