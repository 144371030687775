import React from 'react'
import { get, memoize } from 'lodash'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import Splash from 'components/splash/Splash'
import Login from 'components/login/Login'
// import Login from 'components/login/FirebaseLogin'
import SignUp from 'components/login/SignUp'
import Subscribe from 'components/subscription/Subscribe'
import App from './App'
import { analytics as firebaseAnalytics } from 'api/firebase/firebase'
import { budgetListener } from 'api/firebase/budgetListener'
import { userListener } from 'api/firebase/userListener'
import userApi from 'api/user/user'
import { setFirebaseUser } from 'api/user/actions'
import { directSignUp } from 'api/user/directSignUp'
import analytics from './analytics'
import {
  isApple,
  standalone,
  activeSubscription,
  activeSharedBudget
} from './util'

/* istanbul ignore next */
const styles = theme => ({
  '@global': {
    body: { backgroundColor: theme.palette.background.default }
  }
})

export class Bootstrap extends React.Component {
  state = {
    userStatusKnown: false
  }
  componentDidMount() {
    const { location, history, setFirebaseUser } = this.props
    history.listen(location => {
      analytics.set({ page: location.pathname })
      analytics.pageview(location.pathname)
      firebaseAnalytics.logEvent('page_view', { page: location.pathname })
    })
    /* istanbul ignore next */
    const loggedIn = firebaseUser => {
      const emailProvider =
        get(firebaseUser, 'providerData.0.providerId') === 'password'
      return emailProvider ? firebaseUser.emailVerified : !!firebaseUser
    }

    userApi.listenToFirebaseUser(
      /* istanbul ignore next */
      firebaseUser => {
        analytics.set({ userId: get(firebaseUser, 'uid') })
        if (loggedIn(firebaseUser)) {
          userListener.listen(firebaseUser)
          budgetListener.listen(firebaseUser)
          setFirebaseUser(firebaseUser)
        } else {
          budgetListener.unsubscribe()
          userListener.unsubscribe()
        }
        this.setState({ userStatusKnown: true })
      }
    )

    // issue #1 https://gitlab.com/divvy-it/divvy/issues/1
    /* istanbul ignore next */
    if (standalone(location) && isApple) {
      history.push('/')
    }
  }
  componentWillUnmount() {
    userListener.unsubscribe()
    budgetListener.unsubscribe()
  }
  appAccessAnalytics = memoize((activeSubscription, activeSharedBudget) => {
    let label = 'none'
    if (activeSubscription && activeSharedBudget) {
      label = 'subscribed and shared'
    } else if (activeSubscription) {
      label = 'subscribed'
    } else if (activeSharedBudget) {
      label = 'shared'
    }
    analytics.event({
      category: 'app',
      action: 'access',
      label
    })
    firebaseAnalytics.logEvent('app_access', { status: label })
  })
  hasAppAccess = (budgets, user) => {
    const userId = get(user, 'id')
    this.appAccessAnalytics(
      activeSubscription(user),
      activeSharedBudget(budgets, userId)
    )
    return activeSubscription(user) || activeSharedBudget(budgets, userId)
  }
  render() {
    const { budgets, budgetsLoaded, firebaseUser, user } = this.props
    const { userStatusKnown } = this.state
    let view = <Splash />

    return (
      <Switch>
        <Route
          path="/sign-up/:provider?"
          render={
            /* istanbul ignore next */
            ({ history, location, match }) => {
              if (userStatusKnown) {
                directSignUp({ history, provider: match?.params?.provider })
                view = <SignUp />
              }
              if (firebaseUser && location.pathname.match('/sign-up')) {
                view = <Redirect to="/" />
              }
              return view
            }
          }
        />
        <Route
          path="/"
          render={
            /* istanbul ignore next */
            () => {
              if (userStatusKnown && firebaseUser && user && budgetsLoaded) {
                view = this.hasAppAccess(budgets, user) ? (
                  <App user={user} />
                ) : (
                  <Subscribe />
                )
              }
              if (userStatusKnown && !firebaseUser) {
                view = <Login />
              }
              return view
            }
          }
        />
      </Switch>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    firebaseUser: state.user.firebaseUser,
    user: state.user.user,
    budgets: state.budgets.items,
    budgetsLoaded: state.budgets.loaded
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFirebaseUser: user => dispatch(setFirebaseUser(user))
  }
}

const StyledBootstrap = withStyles(styles)(Bootstrap)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StyledBootstrap)
)
