import React from 'react'
import { connect } from 'react-redux'

function withRoles(WrappedComponent, roles = [], budget) {
  function WithRoles({
    budget,
    budgets,
    user,
    // unused redux prop
    dispatch,
    ...rest
  }) {
    const userId = user?.id
    const role = budget?.roles?.[`${userId}`]?.type
    return roles.includes(role) ? (
      <WrappedComponent budget={budget} user={user} {...rest} />
    ) : (
      // this is in a span to accommodate material-ui tooltips
      // https://material-ui.com/demos/tooltips/#disabled-elements
      <span>
        <WrappedComponent budget={budget} user={user} {...rest} disabled />
      </span>
    )
  }
  /* istanbul ignore next */
  const mapStateToProps = (state, ownProps) => {
    return {
      user: state.user.user,
      budget: budget || state.budgets.activeBudget,
      budgets: state.budgets.items
    }
  }
  WithRoles.displayName = `WithRoles(${getDisplayName(WrappedComponent)})`
  return connect(mapStateToProps, null)(WithRoles)
}

function getDisplayName(WrappedComponent) {
  /* istanbul ignore next */
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withRoles
