/* istanbul ignore file */
import store from 'store'
import userApi from 'api/user/user'
import { setUser } from 'api/user/actions'

// The intend of this module is to hydrate the user redux store
// utilizing a firebase real-time listener

let unsubscribe = () => {}
export const userListener = {
  listen: firebaseUser => {
    unsubscribe()
    unsubscribe = userApi.listen(
      firebaseUser,
      doc => {
        const data = doc.data()
        let user = { id: doc.id, ...data }
        if (!doc.exists) {
          user = null
        }
        store.dispatch(setUser(user))
      },
      err => {
        // This may occur if there are no available resources yet
        console.info('User Listener:\n', err)
      }
    )
  },
  unsubscribe: () => unsubscribe()
}
