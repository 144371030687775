import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { MONTH_FORMAT } from './../../constants'

/* istanbul ignore next */
const styles = theme => ({
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing()}px`,
    paddingTop: 0
  },
  iconButton: {
    fontSize: '1rem'
  },
  centerButton: {
    color: theme.palette.text.primary,
    padding: `0 ${theme.spacing(5)}px`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})

export const TimeNav = ({ classes, user, match, location }) => {
  const month = match.params.month
  const previousMonth = moment(month).subtract(1, 'M')
  const nextMonth = moment(month).add(1, 'M')
  const previousUrl = location.pathname.replace(
    `/${month}`,
    `/${previousMonth.format(MONTH_FORMAT)}`
  )
  const nextUrl = location.pathname.replace(
    `/${month}`,
    `/${nextMonth.format(MONTH_FORMAT)}`
  )
  const userCreatedAt = moment(user.metadata.creationTime).format(MONTH_FORMAT)
  const oldestDate = moment(userCreatedAt)
  return (
    <nav className={classes.nav} data-cy="time-nav">
      <IconButton
        data-cy="prevMonth"
        aria-label="previous month budget"
        className={classes.iconButton}
        component={Link}
        // the oldestDate is the date the users was created minus 1 month
        disabled={previousMonth < oldestDate.subtract(1, 'M')}
        to={previousUrl}
      >
        {previousMonth.format('MMM')}
      </IconButton>
      <IconButton
        data-cy="currentMonth"
        aria-label={`month ${moment(month).format('MMM YYYY')} budget`}
        className={classes.centerButton}
        component={Link}
        focusRipple={false}
        to={`/${month}`}
      >
        <Typography variant="h6">{moment(month).format('MMM YYYY')}</Typography>
      </IconButton>
      <IconButton
        data-cy="nextMonth"
        aria-label="next month budget"
        className={classes.iconButton}
        component={Link}
        disabled={nextMonth > moment()}
        to={nextUrl}
      >
        {nextMonth.format('MMM')}
      </IconButton>
    </nav>
  )
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.firebaseUser
  }
}
export default connect(mapStateToProps, null)(withStyles(styles)(TimeNav))
