import React from 'react'
import Typography from '@material-ui/core/Typography'
import Logo from 'components/logo/Logo'
import { APP_NAME } from './../../constants'

export default () => (
  <article
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}
  >
    <section
      style={{
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Logo />
    </section>
    <section style={{ marginBottom: '1rem' }}>
      <Typography color="textPrimary" variant="h4">
        {APP_NAME}
      </Typography>
    </section>
  </article>
)
