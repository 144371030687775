const GOOGLE_ANALYTICS_ENVIRONMENTS = {
  development: {
    trackingId: 'UA-146207092-1'
  },
  staging: {
    trackingId: 'UA-146207092-2'
  },
  production: {
    trackingId: 'UA-17211523-16'
  },
  paid: {
    trackingId: 'UA-146207092-3'
  }
}
export const GOOGLE_ANALYTICS =
  GOOGLE_ANALYTICS_ENVIRONMENTS[process.env.REACT_APP_ENV || 'development']

const STRIPE_ENVIRONMENTS = {
  development: {
    key: 'pk_test_f2ZUWGDGUJZDlQPyVDwY9K2d',
    amount: 6000
  },
  staging: {
    key: 'pk_test_BoN9BGLhAsJ81UIh0on2X25R',
    amount: 6000
  },
  production: {
    key: 'pk_test_97nmWBzrsJUMFU8L3cJ6rqRY',
    amount: 6000
  },
  paid: {
    // key: 'pk_test_97nmWBzrsJUMFU8L3cJ6rqRY',
    key: 'pk_live_O5D9TY5tI4m0lJETPdyqUwG0',
    amount: 6000
  }
}
export const STRIPE_CHECKOUT =
  STRIPE_ENVIRONMENTS[process.env.REACT_APP_ENV || 'development']

// TODO: use correct plaid keys for staging and production
const PLAID_ENVIRONMENTS = {
  development: {
    publicKey: '88f29ed80c59180cb240d76bbebcfc',
    env: 'sandbox'
  },
  staging: {
    publicKey: '88f29ed80c59180cb240d76bbebcfc',
    env: 'sandbox'
  },
  production: {
    publicKey: '88f29ed80c59180cb240d76bbebcfc',
    env: 'development'
  },
  paid: {
    publicKey: '88f29ed80c59180cb240d76bbebcfc',
    env: 'production'
  }
}
export const PLAID_CONFIG =
  PLAID_ENVIRONMENTS[process.env.REACT_APP_ENV || 'development']

const FIREBASE_ENVIRONMENTS = {
  development: {
    appId: '1:812010414119:web:b948ee8770eec6ae9eae02',
    apiKey: 'AIzaSyALVgsjWpf71axx90tdb1iMq_wyHomLd8c',
    authDomain: 'keepify-dev.firebaseapp.com',
    databaseURL: 'https://keepify-dev.firebaseio.com',
    functionsURL: 'https://us-central1-keepify-dev.cloudfunctions.net',
    measurementId: 'G-P5GHWT9GGC',
    messagingSenderId: '812010414119',
    projectId: 'keepify-dev',
    storageBucket: 'keepify-dev.appspot.com'
  },
  staging: {
    appId: '1:1008930911490:web:9b14ee0b0d8c63abd67e1b',
    apiKey: 'AIzaSyAT2woIu7-bxQryVdNrFuseIINJOAlTT0I',
    authDomain: 'keepify-staging-dde6c.firebaseapp.com',
    databaseURL: 'https://keepify-staging-dde6c.firebaseio.com',
    functionsURL: 'https://us-central1-keepify-dev.cloudfunctions.net',
    measurementId: 'G-Y6NBPF2D3T',
    messagingSenderId: '1008930911490',
    projectId: 'keepify-staging-dde6c',
    storageBucket: 'keepify-staging-dde6c.appspot.com'
  },
  production: {
    appId: '1:900217639057:web:e9fcaa8229fe983cacaf30',
    apiKey: 'AIzaSyAWWy_ytM8jraiFxgYgN1PFLvkP0ohoHWY',
    authDomain: 'divvy-it.firebaseapp.com',
    databaseURL: 'https://divvy-it.firebaseio.com',
    functionsURL: 'https://us-central1-keepify-dev.cloudfunctions.net',
    measurementId: 'G-C1BBHZNLSC',
    messagingSenderId: '900217639057',
    projectId: 'divvy-it',
    storageBucket: 'divvy-it.appspot.com'
  },
  paid: {
    appId: '1:1090970993282:web:efe0620082b22191952a9b',
    apiKey: 'AIzaSyCR-6vaRnDola0xcscPfMYdj74F7KGDJb0',
    authDomain: 'keepifi.firebaseapp.com',
    // authDomain: 'app.keepify.com',
    databaseURL: 'https://keepifi.firebaseio.com',
    functionsURL: 'https://us-central1-keepifi.cloudfunctions.net',
    measurementId: 'G-QQHKDQ34FM',
    messagingSenderId: '1090970993282',
    projectId: 'keepifi',
    storageBucket: 'keepifi.appspot.com'
  }
}
export const FIREBASE_CONFIG =
  FIREBASE_ENVIRONMENTS[process.env.REACT_APP_ENV || 'development']
