import React from 'react'
import { connect } from 'react-redux'
import { memoize } from 'lodash'
import moment from 'moment'
import { db } from './firebase'
import { setTransactions } from './../transactions/actions'
import { setCategoryTransactions } from './../categoryTransactions/actions'
import { setAccountTransactions } from './../accountTransactions/actions'

/* istanbul ignore file */

let unsubscribe = () => {}
const withMonthQuery = (ref, month) => {
  const minDate = moment(month).subtract(1, 'day')
  const maxDate = moment(month).add(1, 'month')
  return month
    ? ref.where('date', '>', minDate._d).where('date', '<', maxDate._d)
    : ref
}
const getRealtime = (
  ref,
  id,
  month,
  setData,
  setCategoryData,
  setAccountData
) => {
  unsubscribe = withMonthQuery(ref, month)
    .orderBy('date', 'desc')
    .onSnapshot(
      querySnapshot => {
        let items = []
        querySnapshot.forEach(doc => {
          // as of firebase version 5.0.0
          // timestamps stored in Cloud Firestore will be read back as
          // Firebase Timestamp objects instead of as system Date objects
          const { date, ...data } = doc.data()
          items.push({
            id: doc.id,
            date: date.toDate(),
            ...data
          })
        })
        setData(items)
        // category transactions will have a categoryId
        setCategoryData(items.filter(item => !!item.categoryId))
        setAccountData(items.filter(item => !!item.accountId))
      },
      err => {
        // This may occur if there are no available resources yet
        console.info('Transactions Listener:\n', err)
        retry(ref, id, month, setData, setCategoryData, setAccountData)
      }
    )
}

const retry = memoize(
  (ref, id, month, setData, setCategoryData, setAccountData) => {
    console.log('memoize attempt transaction listener')
    getRealtime(ref, id, month, setData, setCategoryData, setAccountData)
  }
)

let _id
let _month
const Listener = ({
  activeBudget = {},
  month,
  setData,
  setCategoryData,
  setAccountData
}) => {
  const id = activeBudget.id
  if (!id) return null
  if (_id === id && _month === month) return null
  unsubscribe()
  _id = id
  _month = month
  const ref = db.collection(`budgets/${id}/transactions`)
  getRealtime(ref, id, month, setData, setCategoryData, setAccountData)
  return null
}

class Container extends React.Component {
  componentWillMount() {
    _id = null
    _month = null
  }
  componentWillUnmount() {
    unsubscribe()
  }
  render() {
    return <Listener {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeBudget: state.budgets.activeBudget,
    month: state.month
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setData: items => dispatch(setTransactions(items)),
    setCategoryData: items => dispatch(setCategoryTransactions(items)),
    setAccountData: items => dispatch(setAccountTransactions(items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
