import React from 'react'
import { connect } from 'react-redux'
import { memoize } from 'lodash'
import { db } from './firebase'
import { setGoals } from './../goals/actions'

/* istanbul ignore file */

let unsubscribe = () => {}
const getRealtime = (ref, budget, month, setData) => {
  unsubscribe = ref.orderBy('name').onSnapshot(
    querySnapshot => {
      let items = []
      querySnapshot.forEach(doc => {
        // as of firebase version 5.0.0
        // timestamps stored in Cloud Firestore will be read back as
        // Firebase Timestamp objects instead of as system Date objects
        let goal = { id: doc.id, ...doc.data() }
        if (goal.targetDate) {
          goal.targetDate = goal.targetDate.toDate()
        }
        items.push(goal)
      })
      setData(items)
    },
    err => {
      // This may occur if there are no available resources yet
      console.info('Goals Listener\n:', err)
      retry(ref, budget, month, setData)
    }
  )
}

const retry = memoize((ref, budget, month, setData) => {
  console.log('memoize attempt goal listener')
  getRealtime(ref, budget, month, setData)
})

let _id
let _month
const Listener = ({ budget = {}, month, setData }) => {
  const id = budget.id
  if (!id || !month) return null
  if (_id === id && _month === month) return null
  unsubscribe()
  _id = id
  _month = month
  const ref = db.collection(`budgets/${id}/goals`)
  getRealtime(ref, budget, month, setData)
  return null
}

class Container extends React.Component {
  componentWillMount() {
    _id = null
    _month = null
  }
  componentWillUnmount() {
    unsubscribe()
  }
  render() {
    return <Listener {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    budget: state.budgets.activeBudget,
    month: state.month
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setData: items => dispatch(setGoals(items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
