import React from 'react'
import { memoize } from 'lodash'
import { connect } from 'react-redux'
import { db } from './firebase'
import { setAccounts } from './../accounts/actions'

/* istanbul ignore file */

let unsubscribe = () => {}
const getRealtime = (ref, budget, setData) => {
  unsubscribe = ref.orderBy('name').onSnapshot(
    querySnapshot => {
      let items = []
      querySnapshot.forEach(doc => {
        items.push({ id: doc.id, ...doc.data() })
      })
      setData(items)
    },
    err => {
      // This may occur if there are no available resources yet
      console.info('Accounts Listener\n:', err)
      retry(ref, budget, setData)
    }
  )
}

const retry = memoize((ref, budget, setData) => {
  console.log('memoize attempt account listener')
  getRealtime(ref, budget, setData)
})

let _id
const Listener = ({ budget = {}, setData }) => {
  const id = budget.id
  if (!id) return null
  if (_id === id) return null
  unsubscribe()
  _id = id
  const ref = db.collection(`budgets/${id}/accounts`)
  getRealtime(ref, budget, setData)
  return null
}

class Container extends React.Component {
  componentWillMount() {
    _id = null
  }
  componentWillUnmount() {
    unsubscribe()
  }
  render() {
    return <Listener {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    budget: state.budgets.activeBudget
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setData: items => dispatch(setAccounts(items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
