import React from 'react'
import { withStyles } from '@material-ui/styles'

/* istanbul ignore next */
const styles = theme => ({
  '@keyframes shuffle': {
    '50%': {
      zIndex: 1,
      transform: 'translate(-50px, -15px)'
    },
    '65%': {
      zIndex: 1,
      transform: 'translate(-50px, -15px)'
    }
  },
  '@keyframes shuffleTwo': {
    '50%': {
      zIndex: 3,
      transform: 'translate(-20px, -10px) rotate(-4deg)'
    },
    '65%': {
      zIndex: 3,
      transform: 'translate(-20px, -10px) rotate(-4deg)'
    }
  },
  '@keyframes shuffleThree': {
    '50%': {
      zIndex: 4,
      transform: 'translate(25px, -6px) rotate(8deg)'
    },
    '65%': {
      zIndex: 4,
      transform: 'translate(25px, -6px) rotate(8deg)'
    }
  },
  shufflePaper: {
    width: 245,
    margin: `${theme.spacing(2)}px auto`
  },
  rectangleOne: {
    zIndex: 3,
    animation: 'shuffle 1.1s infinite linear'
  },
  rectangleTwo: {
    zIndex: 2,
    animation: 'shuffleTwo 1.1s  infinite linear'
  },
  rectangleThree: {
    zIndex: 1,
    animation: 'shuffleThree 1.1s infinite linear'
  }
})

export const ShufflePaper = ({ classes, height = '250', width = '250' }) => {
  return (
    <svg
      className={classes.shufflePaper}
      aria-labelledby="shufflePaper"
      role="img"
      height={height}
      width={width}
      viewBox="-5 -15 250 250"
      preserveAspectRatio="xMidYMid meet"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="shufflePaper">Shuffling Paper - processing</title>
      <defs>
        <filter
          x="-3.6%"
          y="-2.7%"
          width="107.1%"
          height="107.1%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <rect
          id="path-2"
          x="10.2661597"
          y="7.69961977"
          width="84.6958175"
          height="111.21673"
        />
        <filter
          x="-8.3%"
          y="-4.5%"
          width="116.5%"
          height="112.6%"
          filterUnits="objectBoundingBox"
          id="filter-3"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
        <rect
          id="path-4"
          x="33.365019"
          y="24.8098859"
          width="84.6958175"
          height="111.21673"
        />
        <filter
          x="-8.3%"
          y="-4.5%"
          width="116.5%"
          height="112.6%"
          filterUnits="objectBoundingBox"
          id="filter-5"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
        <rect
          id="path-6"
          x="70.1520913"
          y="15.3992395"
          width="84.6958175"
          height="111.21673"
        />
        <filter
          x="-8.3%"
          y="-4.5%"
          width="116.5%"
          height="112.6%"
          filterUnits="objectBoundingBox"
          id="filter-7"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="email-verification"
          transform="translate(-483.000000, -263.000000)"
        >
          <g
            id="Group"
            filter="url(#filter-1)"
            transform="translate(497.000000, 266.000000)"
          >
            <g id="Group-2" transform="translate(27.376426, 43.631179)">
              <g
                id="rectangleThree"
                className={classes.rectangleThree}
                transform="translate(62.614068, 63.307985) rotate(-11.000000) translate(-52.614068, -63.307985) "
              >
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-3)"
                  xlinkHref="#path-2"
                />
                <use fill="#449EEB" fillRule="evenodd" xlinkHref="#path-2" />
              </g>
              <g
                id="rectangleTwo"
                className={classes.rectangleTwo}
                transform="translate(95.712928, 80.418251) rotate(2.000000) translate(-75.712928, -80.418251) "
              >
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-5)"
                  xlinkHref="#path-4"
                />
                <use fill="#EAFCFF" fillRule="evenodd" xlinkHref="#path-4" />
              </g>
              <g
                id="rectangleOne"
                className={classes.rectangleOne}
                transform="translate(140.500000, 65.007605) rotate(18.000000) translate(-112.500000, -71.007605) "
              >
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-7)"
                  xlinkHref="#path-6"
                />
                <use fill="#0071BD" fillRule="evenodd" xlinkHref="#path-6" />
              </g>
            </g>
            <circle
              id="Oval-3"
              stroke="#0071BD"
              strokeWidth="2"
              cx="105"
              cy="105"
              r="105.5"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default withStyles(styles)(ShufflePaper)
