import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/styles'

/* istanbul ignore next */
const styles = theme => ({
  formControl: {
    margin: theme.spacing()
  }
})

export class Field extends React.Component {
  // static propTypes = {
  //   placeholder: PropTypes.string,
  //   name: PropTypes.string.isRequired,
  //   value: PropTypes.string,
  //   validate: PropTypes.func,
  //   onChange: PropTypes.func.isRequired,
  // }
  state = {
    value: this.props.value,
    error: false
  }
  static getDerivedStateFromProps(props, state) {
    let newState = null
    if (props.value !== state.value) {
      newState = { ...newState, value: props.value }
    }
    if (props.error !== state.error) {
      newState = { ...newState, error: props.error || state.error }
    }
    return newState
  }
  onChange = evt => {
    const { name, onChange, validate } = this.props
    const value = evt.target.value
    const error = validate ? validate(value) : false

    this.setState({ value, error })

    onChange({ name, value, error })
  }
  render() {
    const {
      classes,
      label,
      placeholder,
      name,
      onChange,
      required,
      error,
      value,
      validate,
      controlClasses = '',
      controlStyle,
      FormControlProps,
      ...rest
    } = this.props
    return (
      <FormControl
        className={`${classes.formControl} ${controlClasses}`}
        aria-describedby={`${name}-text`}
        error={!!this.state.error}
        style={controlStyle}
        {...FormControlProps}
      >
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Input
          id={name}
          placeholder={placeholder}
          name={name}
          value={this.state.value}
          onChange={this.onChange}
          {...rest}
        />
        {this.state.error && (
          <FormHelperText id={`${name}-text`}>
            {this.state.error}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}
export default withStyles(styles)(Field)
