import React, { Suspense, lazy } from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'
import { Route, Redirect, Switch } from 'react-router-dom'
import Header from 'components/header/Header'
import NavDrawer from 'components/navDrawer/NavDrawer'
import TimeNav from 'components/timeNav/TimeNav'
import MonthRedux from 'components/monthRedux/MonthRedux'
import ActiveBudgetListener from 'api/firebase/ActiveBudgetListener'
import AccountsListener from 'api/firebase/AccountsListener'
import CategoriesListener from 'api/firebase/CategoriesListener'
import GoalsListener from 'api/firebase/GoalsListener'
import TransactionsListener from 'api/firebase/TransactionsListener'
import GoalTransactionsListener from 'api/firebase/GoalTransactionsListener'
import userApi from 'api/user/user'
import { MONTH_FORMAT } from './constants'
import { activeSubscription } from './util'

const Accounts = lazy(() =>
  import(/* webpackChunkName: "Accounts" */ 'components/accounts/Container')
)
const Account = lazy(() =>
  import(/* webpackChunkName: "Account" */ 'components/account/Account')
)
const Budget = lazy(() =>
  import(/* webpackChunkName: "Budget" */ 'components/budget/Budget')
)
const Budgets = lazy(() =>
  import(/* webpackChunkName: "Budgets" */ 'components/budgets/Container')
)
const Categories = lazy(() =>
  import(/* webpackChunkName: "Categories" */ 'components/categories/Container')
)
const Category = lazy(() =>
  import(/* webpackChunkName: "Category" */ 'components/category/Category')
)
const Goals = lazy(() =>
  import(/* webpackChunkName: "Goals" */ 'components/goals/Container')
)
const Goal = lazy(() =>
  import(/* webpackChunkName: "Goal" */ 'components/goal/Goal')
)
const Reports = lazy(() =>
  import(/* webpackChunkName: "Reports" */ 'components/reports/Reports')
)
const Settings = lazy(() =>
  import(/* webpackChunkName: "Settings" */ 'components/settings/Settings')
)
const Transactions = lazy(() =>
  import(
    /* webpackChunkName: "Transactions" */ 'components/transactions/Container'
  )
)
const OnBoard = lazy(() =>
  import(/* webpackChunkName: "OnBoard" */ 'components/onBoard/Container')
)
const OnBoardStepper = lazy(() =>
  import(
    /* webpackChunkName: "OnBoardStepper" */ 'components/onBoard/stepper/BudgetsStepper'
  )
)
const AddTransaction = lazy(() =>
  import(/* webpackChunkName: "AddTransaction" */ 'components/transactions/Add')
)
const EditTransaction = lazy(() =>
  import(
    /* webpackChunkName: "EditTransaction" */ 'components/transactions/Edit'
  )
)
const AddGoalTransaction = lazy(() =>
  import(
    /* webpackChunkName: "AddGoalTransaction" */ 'components/transactions/AddGoal'
  )
)
const EditGoalTransaction = lazy(() =>
  import(
    /* webpackChunkName: "EditGoalTransaction" */ 'components/transactions/EditGoal'
  )
)
const AddAccount = lazy(() =>
  import(/* webpackChunkName: "AddAccount" */ 'components/accounts/Add')
)
const EditAccount = lazy(() =>
  import(/* webpackChunkName: "EditAccount" */ 'components/accounts/Edit')
)
const LinkAccount = lazy(() =>
  import(/* webpackChunkName: "LinkAccount" */ 'components/accounts/Link')
)
const AddCategory = lazy(() =>
  import(/* webpackChunkName: "AddCategory" */ 'components/categories/Add')
)
const EditCategory = lazy(() =>
  import(/* webpackChunkName: "EditCategory" */ 'components/categories/Edit')
)
const AddGoal = lazy(() =>
  import(/* webpackChunkName: "AddGoal" */ 'components/goals/Add')
)
const EditGoal = lazy(() =>
  import(/* webpackChunkName: "EditGoal" */ 'components/goals/Edit')
)
const AddBudget = lazy(() =>
  import(/* webpackChunkName: "AddBudget" */ 'components/budgets/Add')
)
const EditBudget = lazy(() =>
  import(/* webpackChunkName: "EditBudget" */ 'components/budgets/Edit')
)
const AddUser = lazy(() =>
  import(/* webpackChunkName: "AddUser" */ 'components/users/Add')
)
const EditUser = lazy(() =>
  import(/* webpackChunkName: "EditUser" */ 'components/users/Edit')
)
const ShuffleMoney = lazy(() =>
  import(
    /* webpackChunkName: "ShuffleMoney" */ 'components/shuffleMoney/ShuffleMoney'
  )
)
const BackButton = lazy(() =>
  import(
    /* webpackChunkName: "BackButton" */ 'components/backButton/BackButton'
  )
)
const InstallPrompt = lazy(() =>
  import(
    /* webpackChunkName: "InstallPrompt" */ 'components/installPrompt/InstallPrompt'
  )
)
const ImportTransactions = lazy(() =>
  import(
    /* webpackChunkName: "ImportTransactions" */ 'components/importTransactions/ImportTransactions'
  )
)
const Subscribe = lazy(() =>
  import(
    /* webpackChunkName: "Subscribe" */ 'components/subscription/Subscribe'
  )
)
const Notifications = lazy(() =>
  import(
    /* webpackChunkName: "Notifications" */ 'components/notifications/Container'
  )
)

/* istanbul ignore next */
const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    width: '100%',
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden'
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  content: {
    boxSizing: 'border-box',
    overflow: 'auto',
    '-webkitOverflowScrolling': 'touch',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    padding: `
      ${theme.spacing(3)}px
      ${theme.spacing()}px
      ${theme.spacing(10)}px`,
    height: 'calc(100% - 56px)',
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64
    }
  }
})

/* istanbul ignore next */
const isValidMonth = match => moment(match.params.month, MONTH_FORMAT).isValid()
/* istanbul ignore next */
const isFutureMonth = match => moment(match.params.month) > moment()

export class App extends React.Component {
  state = {
    drawerActive: false
  }
  toggleDrawer = () => this.setState({ drawerActive: !this.state.drawerActive })
  render() {
    const { classes, user } = this.props
    const { drawerActive } = this.state
    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          {activeSubscription(user) && (
            <Suspense fallback={null}>
              <Route path="/" component={OnBoard} />
            </Suspense>
          )}
          <ActiveBudgetListener />
          <AccountsListener />
          <Route
            exact
            path="/"
            render={
              /* istanbul ignore next */
              () => <Redirect to={`/${moment().format(MONTH_FORMAT)}`} />
            }
          />
          <Route
            path="/:month"
            render={
              /* istanbul ignore next */
              ({ match }) =>
                isValidMonth(match) && isFutureMonth(match) ? (
                  <Redirect to={`/${moment().format(MONTH_FORMAT)}`} />
                ) : null
            }
          />
          <Route
            exact
            path="/shared/:budgetId"
            render={
              /* istanbul ignore next */
              ({ match }) => {
                userApi.update({
                  id: user.id,
                  activeBudgetId: match.params.budgetId
                })
                return <Redirect to={`/${moment().format(MONTH_FORMAT)}`} />
              }
            }
          />
          <Route
            path="/:month"
            render={
              /* istanbul ignore next */
              ({ match }) =>
                isValidMonth(match) && !isFutureMonth(match) ? (
                  <React.Fragment>
                    <CategoriesListener />
                    <GoalsListener />
                    <TransactionsListener />
                    <GoalTransactionsListener />
                    <MonthRedux month={match.params.month} />
                  </React.Fragment>
                ) : null
            }
          />
          <Header onMenuClick={this.toggleDrawer} />
          <NavDrawer active={drawerActive} onClose={this.toggleDrawer} />
          <main className={classes.content}>
            <Route
              path="/:month"
              render={
                /* istanbul ignore next */
                props =>
                  isValidMonth(props.match) ? <TimeNav {...props} /> : null
              }
            />
            <Suspense fallback={null}>
              <Switch>
                <Route
                  exact
                  path="/:month/envelopes/(add-category|edit-category|notifications|notifications/link-account)?"
                  component={Categories}
                />
                <Route path="/:month/envelopes/:id" component={Category} />
                <Route
                  exact
                  path="/:month/accounts/(add-account|edit-account|link-account|notifications|notifications/link-account)?"
                  component={Accounts}
                />
                <Route path="/:month/accounts/:id" component={Account} />
                <Route
                  exact
                  path="/:month/goals/(add-goal|edit-goal|notifications|notifications/link-account)?"
                  component={Goals}
                />
                <Route path="/:month/goals/:id" component={Goal} />
                <Route
                  path="/:month/transactions/(add-transaction|edit-transaction|notifications|notifications/link-account)?"
                  component={Transactions}
                />
                <Route
                  exact
                  path="/budgets/(add-budget|edit-budget|add-user|edit-user|notifications|notifications/link-account)?"
                  component={Budgets}
                />
                <Route
                  exact
                  path="/reports/:chartDataType/:chartDisplayType/:chartTimeRange"
                  component={Reports}
                />
                <Route path="/reports" component={Reports} />
                <Route path="/goals" component={Goals} />
                <Route path="/settings" component={Settings} />
                <Route
                  path="/subscribe"
                  render={
                    /* istanbul ignore next */ ({ history }) => {
                      return (
                        <Subscribe
                          logo={false}
                          onPaid={() => history.push('/')}
                          style={{ margin: 0 }}
                        />
                      )
                    }
                  }
                />
                <Route
                  path="/import-transactions"
                  component={ImportTransactions}
                />
                <Route
                  path="/:month"
                  render={
                    /* istanbul ignore next */
                    props => (
                      <React.Fragment>
                        {activeSubscription(user) ? <OnBoardStepper /> : null}
                        <Budget {...props} />
                      </React.Fragment>
                    )
                  }
                />
              </Switch>
            </Suspense>
            <Suspense fallback={null}>
              <Route path="/" component={InstallPrompt} />
              <Route path="/" component={AddTransaction} />
              <Route path="/" component={EditTransaction} />
              <Route path="/" component={AddGoalTransaction} />
              <Route path="/" component={EditGoalTransaction} />
              <Route path="/" component={AddCategory} />
              <Route path="/" component={EditCategory} />
              <Route path="/" component={AddAccount} />
              <Route path="/" component={EditAccount} />
              <Route path="/" component={LinkAccount} />
              <Route path="/" component={AddGoal} />
              <Route path="/" component={EditGoal} />
              <Route path="/" component={ShuffleMoney} />
              <Route path="/" component={AddUser} />
              <Route path="/" component={EditUser} />
              <Route path="/" component={Notifications} />
              <Route path="/budgets" component={AddBudget} />
              <Route path="/budgets" component={EditBudget} />
              {/* show the back button on all pages but the budget/home page */}
              <Route path="/:month/:other" component={BackButton} />
              <Route path="/budgets" component={BackButton} />
              <Route path="/settings" component={BackButton} />
              <Route path="/import-transactions" component={BackButton} />
            </Suspense>
          </main>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(App)
