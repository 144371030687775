import { combineReducers } from 'redux'
import transactions from 'api/transactions/reducer'
import categories from 'api/categories/reducer'
import categoryTransactions from 'api/categoryTransactions/reducer'
import accounts from 'api/accounts/reducer'
import accountTransactions from 'api/accountTransactions/reducer'
import goals from 'api/goals/reducer'
import goalTransactions from 'api/goalTransactions/reducer'
import budgets from 'api/budgets/reducer'
import user from 'api/user/reducer'
import month from 'components/monthRedux/reducer'
import notifications from 'components/notifications/reducer'
import theme from 'components/theme/reducer'

export default combineReducers({
  transactions,
  categories,
  categoryTransactions,
  accounts,
  accountTransactions,
  goals,
  goalTransactions,
  budgets,
  user,
  month,
  notifications,
  theme
})
