import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { db } from './firebase'
import { MONTH_FORMAT } from './../../constants'
import { setGoalTransactions } from './../goalTransactions/actions'

/* istanbul ignore file */

let unsubscribe = () => {}
const monthFilter = (items, month) => {
  const maxDate = moment(month).add(1, 'month')
  return items.filter(item => item.date < maxDate)
}
const getRealtime = (
  ref,
  id,
  month = moment().format(MONTH_FORMAT),
  setData
) => {
  unsubscribe = ref.where('goalId', '>', '').onSnapshot(
    querySnapshot => {
      let items = []
      querySnapshot.forEach(doc => {
        // as of firebase version 5.0.0
        // timestamps stored in Cloud Firestore will be read back as
        // Firebase Timestamp objects instead of as system Date objects
        const { date, ...data } = doc.data()
        items.push({
          id: doc.id,
          date: date.toDate(),
          ...data
        })
      })
      items = monthFilter(items, month)
      setData(items)
    },
    err => {
      // This may occur if there are no available resources yet
      console.info('Goal Transactions Listener:\n', err)
    }
  )
}

let _id
let _month
const Listener = ({ activeBudget = {}, month, setData }) => {
  const id = activeBudget.id
  if (!id) return null
  if (_id === id && _month === month) return null
  unsubscribe()
  _id = id
  _month = month
  const ref = db.collection(`budgets/${id}/transactions`)
  getRealtime(ref, id, month, setData)
  return null
}

class Container extends React.Component {
  componentWillMount() {
    _id = null
    _month = null
  }
  componentWillUnmount() {
    unsubscribe()
  }
  render() {
    return <Listener {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeBudget: state.budgets.activeBudget,
    month: state.month
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setData: items => dispatch(setGoalTransactions(items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
