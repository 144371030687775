import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import Popper from '@material-ui/core/Popper'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Scheduler from 'components/scheduler/Scheduler'
import withRoles from 'components/withRoles/withRoles'
import roles from 'components/users/roles'
import { withSubscription } from 'components/withSubscription/withSubscription'
import userApi from 'api/user/user'
import { setFirebaseUser } from 'api/user/actions'
import { getGravatarURL } from './../../util'
import { FAQ_LINK, HELP_EMAIL, SUBSCRIPTION_STATUS } from './../../constants'

const WithSubscriptionMenuItem = withSubscription(
  MenuItem,
  [SUBSCRIPTION_STATUS.CANCELLED, SUBSCRIPTION_STATUS.PAST_DUE],
  { hide: true }
)

const WithRolesMenuItem = withRoles(MenuItem, [roles.OWNER, roles.EDITOR])

/* istanbul ignore next */
const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.light,
    padding: 0
  },
  grow: {
    transformOrigin: 'bottom'
  },
  popper: {
    marginTop: theme.spacing(0.5)
  }
})

// https://material-ui.com/guides/composition/#caveat-with-inlining
const MenuItemLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
))

export const UserMenu = ({ classes, firebaseUser, setFirebaseUser, user }) => {
  const [open, setOpen] = useState(false)
  const [photoURL] = useState(
    firebaseUser.photoURL || getGravatarURL(firebaseUser.email)
  )
  const anchorRef = useRef(null)

  const handleToggle = () => setOpen(prevOpen => !prevOpen)
  const handleClose = evt => {
    if (anchorRef.current && anchorRef.current.contains(evt.target)) {
      return
    }
    setOpen(false)
  }
  const signOut = () => {
    return userApi.signOut().then(() => setFirebaseUser(null))
  }
  return (
    <>
      <Tooltip title={firebaseUser.email || ''}>
        <Avatar
          data-cy="user-avatar"
          alt={firebaseUser.email}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          className={classes.avatar}
          component={IconButton}
          onClick={handleToggle}
          ref={anchorRef}
          src={photoURL}
        />
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        className={classes.popper}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} className={classes.grow}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <WithSubscriptionMenuItem
                    component={MenuItemLink}
                    onClick={handleClose}
                    to="/subscribe"
                  >
                    Subscribe
                  </WithSubscriptionMenuItem>
                  <Scheduler
                    component={MenuItem}
                    onClick={handleClose}
                    data-cy="schedule-consult-btn"
                  >
                    Schedule Consult
                  </Scheduler>
                  <WithRolesMenuItem
                    data-cy="settings-btn"
                    component={MenuItemLink}
                    onClick={handleClose}
                    to="/settings"
                  >
                    Settings
                  </WithRolesMenuItem>
                  <MenuItem
                    data-cy="faq-btn"
                    component="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={FAQ_LINK}
                  >
                    FAQ
                  </MenuItem>
                  <MenuItem
                    data-cy="help-btn"
                    component="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`mailto:${HELP_EMAIL}`}
                  >
                    Contact Support
                  </MenuItem>
                  <MenuItem
                    data-cy="sign-out-btn"
                    onClick={
                      /* istanbul ignore next */ evt => {
                        handleClose(evt)
                        signOut()
                      }
                    }
                  >
                    Sign out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    firebaseUser: state.user.firebaseUser,
    user: state.user.user
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFirebaseUser: user => dispatch(setFirebaseUser(user))
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserMenu))
