import React from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

export default ({ open, message, handleClose, action, actionText }) => {
  return (
    <Snackbar open={open}>
      <SnackbarContent
        aria-describedby="message-id"
        style={{ flexWrap: 'nowrap' }}
        message={
          <Typography color="inherit" id="message-id">
            {message}
          </Typography>
        }
        action={[
          action && (
            <Button
              key="resend-email"
              color="secondary"
              onClick={/* istanbul ignore next */ e => action()}
            >
              {actionText}
            </Button>
          ),
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ].filter(v => v != null)}
      />
    </Snackbar>
  )
}
