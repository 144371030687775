import React, { useEffect, useState } from 'react'
import * as serviceWorker from './serviceWorker'
import OfflineReady from './OfflineReady'
import Refresh from './Refresh'

export default ({ children }) => {
  const [offlineReady, setOfflineReady] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [registration, setRegistration] = useState(null)

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // Examples on prompting refresh: https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
    // My own basic examples of the custom service worker: https://github.com/ralphsmith80/service-worker
    serviceWorker.register({
      onSuccess: () => {
        setOfflineReady(true)
      },
      onUpdate: _registration => {
        setRegistration(_registration)
        setRefresh(true)
      }
    })
  }, [])

  return (
    <>
      {children}
      <Refresh
        action={() => {
          registration &&
            registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          setRefresh(false)
        }}
        handleClose={() => setRefresh(false)}
        open={refresh}
      />
      <OfflineReady
        handleClose={() => setOfflineReady(false)}
        open={offlineReady}
      />
    </>
  )
}
