import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { get } from 'lodash'
import UserList from './UsersList'
import { getOtherUsers } from './getUsers'

export const Container = ({
  budget,
  firebaseUser,
  getOtherUsers,
  history,
  location
}) => {
  const [users, setUsers] = useState([])
  useEffect(() => {
    getOtherUsers(budget, firebaseUser.uid).then(users => setUsers(users))
  }, [budget, firebaseUser, getOtherUsers])
  return (
    <UserList
      users={users.map(user => ({
        ...user,
        role: get(budget, `roles["${user.id}"].type`, undefined)
      }))}
      onAddUser={
        /* istanbul ignore next */
        evt => history.push(`${location.pathname}/add-user`)
      }
      onEditUser={
        /* istanbul ignore next */
        (evt, item) => history.push(`${location.pathname}/edit-user`, { item })
      }
    />
  )
}
Container.defaultProps = {
  getOtherUsers: getOtherUsers
}

/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => {
  return {
    budget: state.budgets.activeBudget,
    firebaseUser: state.user.firebaseUser
  }
}
export default withRouter(connect(mapStateToProps, null)(Container))
