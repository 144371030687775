import { SET_CATEGORY_TRANSACTIONS } from './actionTypes'

const initialState = { items: [] }

const items = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_TRANSACTIONS:
      return { ...state, items: action.items }
    default:
      return state
  }
}

export default items
